const Tag = ({ tag = "div", children, props }) => {
  if (tag === "div") {
    return <div {...props}>{children}</div>;
  } else if (tag === "li") {
    return <li {...props}>{children}</li>;
  } else if (tag === "span") {
    return <span {...props}>{children}</span>;
  } else if (tag === "button") {
    return <button {...props}>{children}</button>;
  }
};

export default Tag;
