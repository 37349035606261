export const ChatInputSectionI18n = {
  "pt-BR": {
    chat: {
      label:
        "Para começar a interagir com um site ou vídeo do YouTube, cole a URL e deixe que nossa função de chat extraia o conteúdo. Você também pode enviar um documento e começar a interagir com ele imediatamente.",
    },
    button: {
      label: "Anexar",
      docs: "Carregue ou arraste um arquivo aqui mesmo\n  ",
      link: "Adicione um vídeo do Youtube ou URL de um site",
    },
    docs: {
      label: "Suportamos esses tipos de arquivos",
    },
    placeholder: {
      main: "Digite sua mensagem",
      image: "Digite sua mensagem para criar uma imagem...",
      docs: "Digite sua mensagem, cole um link de site ou link de vídeo do YouTube.",
      assistantDisabled:
        "Você não pode enviar mensagens para este assistente, pois ele está desativado.",
    },
    notifications: {
      error:
        "O tamanho do arquivo excedeu o limite de {{pdfSizeLimit}}MB para o plano.",
    },
    processingVideoTitle:
      "Por favor, aguarde, estamos processando o vídeo do Youtube",
    processingURLTitle: "Por favor, aguarde, estamos processando a URL",
  },
  "en-US": {
    chat: {
      label:
        "To start chatting with a website or YouTube video, paste its URL and let our chat feature extract the content. You can also upload a document and start interacting with it right away.",
    },
    button: {
      label: "Attach",
      docs: "Upload or drop a file right here",
      link: "Add Youtube video or website url",
    },
    docs: {
      label: "We support these types of files",
    },
    placeholder: {
      main: "Type your message",
      image: "Type your message to create an image...",
      docs: "Type your message, paste a website link or YouTube video link.",
      assistantDisabled:
        "You can't send messages to this assistant because it's disabled.",
    },
    notifications: {
      error: "PDF size exceeds the limit of {{pdfSizeLimit}}MB for the plan.",
    },
    processingVideoTitle: "Please wait, we're processing the Youtube video",
    processingURLTitle: "Please wait, we're processing the URL",
  },
  "es-ES": {
    chat: {
      label:
        "Para comenzar a interactuar con un sitio web o video de YouTube, pega su URL y deja que nuestra función de chat extraiga el contenido. También puedes subir un documento y comenzar a interactuar con él de inmediato.",
    },
    button: {
      label: "Adjuntar",
      docs: "Sube o arrastra un archivo justo aquí",
      link: "Añade un video de Youtube o URL de un sitio web",
    },
    docs: {
      label: "Soportamos estos tipos de archivos",
    },
    placeholder: {
      main: "Ingrese su mensaje",
      image: "Escribe tu mensaje para crear una imagen...",
      docs: "Escriba su mensaje, pegue el enlace de un sitio web o un enlace de video de YouTube.",
      assistantDisabled:
        "No puedes enviar mensajes a este asistente porque está desactivado.",
    },
    notifications: {
      error:
        "El tamaño del PDF excede el límite de {{pdfSizeLimit}}MB MB para el plan.",
    },
    processingVideoTitle:
      "Por favor espera, estamos procesando el video de Youtube",
    processingURLTitle: "Por favor espera, estamos procesando la URL",
  },
  "fil-PH": {
    // Adding Filipino language
    chat: {
      label:
        "Para magsimula ng pakikipag-chat sa isang website o video sa YouTube, i-paste ang URL at hayaang ang aming chat feature ay ekstrahin ang nilalaman. Maaari mo rin i-upload ang isang dokumento at magsimula ng pakikipag-ugnayan dito agad.",
    },
    button: {
      label: "Ikabit",
      docs: "I-upload o i-drop ang isang file dito mismo",
      link: "Magdagdag ng video sa Youtube o URL ng website",
    },
    docs: {
      label: "Suportado namin ang mga uri ng file na ito",
    },
    placeholder: {
      main: "I-type ang iyong mensahe",
      image: "I-type ang iyong mensahe para gumawa ng larawan...",
      docs: "I-type ang iyong mensahe, i-paste ang link ng website o link ng video sa YouTube.",
      assistantDisabled:
        "Hindi mo maaring ipadala ang mga mensahe sa asistente na ito dahil ito ay hindi gumagana.",
    },
    notifications: {
      error:
        "Ang laki ng file ay lumampas sa limitasyon na {{pdfSizeLimit}}MB para sa plano.",
    },
    processingVideoTitle:
      "Mangyaring maghintay, binobroceso namin ang Youtube video",
    processingURLTitle: "Mangyaring maghintay, binobroceso namin ang URL",
  },
};
