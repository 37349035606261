export const ModalChannelRenameI18n = {
  "pt-BR": {
    page: {
      label: "Renomear chat",
    },
    input: {
      title: "Novo título do chat",
      placeholder: "Seu título...",
    },
    button: {
      close: "Fechar",
      rename: "Renomear",
    },
    success: {
      message: "Chat renomeado com sucesso!",
    },
  },
  "en-US": {
    page: {
      label: "Rename chat",
    },
    input: {
      title: "New chat title",
      placeholder: "Your title...",
    },
    button: {
      close: "Close",
      rename: "Rename",
    },
    success: {
      message: "Chat renamed successfully!",
    },
  },
  "es-ES": {
    page: {
      label: "Nuevo chat",
    },
    input: {
      title: "Nuevo título del chat",
      placeholder: "Tu título...",
    },
    button: {
      close: "Cerrar",
      rename: "Rebautizar",
    },
    success: {
      message: "¡Chat renombrado con éxito!",
    },
  },
  "fil-PH": {
    page: {
      label: "Palitan ang pangalan ng chat",
    },
    input: {
      title: "Bagong pamagat ng chat",
      placeholder: "Iyong pamagat...",
    },
    button: {
      close: "Isara",
      rename: "Palitan",
    },
    success: {
      message: "Chat na pinalitan ng pangalan nang matagumpay!",
    },
  },
};
