import { useEffect } from "react";
import Conversation from "../Conversation";
import {
  ChatRepository,
  makeChatId,
} from "../../../../../services/indexedDB/chat-repository";
import { useGetConfigsMainChat } from "../../../../../hooks/smallHooks";
import { useChatStore } from "../../../../../state/chat";
import { showErrorNotification } from "../../../../../services/notifications";

const ChatMain = () => {
  const configsMainChat = useGetConfigsMainChat();
  const { setMessages } = useChatStore();
  const db = new ChatRepository();
  // @ts-ignore
  const selectedChat = configsMainChat?.chatData;

  useEffect(() => {
    const getMessages = async () => {
      try {
        const chatId = makeChatId(selectedChat._id, selectedChat.userId);

        const dbMessages = await db.getMessagesByChatId(chatId);

        if (!dbMessages || dbMessages?.length === 0) {
          const existingChat = await db.get(chatId);

          if (!existingChat) {
            await db.add({
              id: chatId,
              messages: [],
            });
          }
        }

        setMessages(dbMessages || []);

        // let messages = dbMessages;

        // if (!messages || messages.length === 0) {
        //   const response = await getChatUserConversationsChannels({
        //     assistantId: selectedChat.assistantId,
        //     channelId: selectedChat._id,
        //   });

        //   const chatTarget = Object.keys(
        //     response.chats_by_channel_and_assistant_id
        //   )[0];
        //   messages =
        //     response.chats_by_channel_and_assistant_id[chatTarget]?.reverse();

        //   const existingChat = await db.get(chatId);

        //   if ((!messages || messages.length === 0) && existingChat) {
        //     setMessages([]);
        //     return;
        //   }

        //   const cryptoMessages = messages?.map(message =>
        //     cryptoService.encrypt(JSON.stringify(message))
        //   );

        //   if (!existingChat) {
        //     await db.add({
        //       id: chatId,
        //       messages: cryptoMessages || [],
        //     });

        //     await clearChannel(selectedChat._id);
        //   } else {
        //     await db.updateChatWithMessage(chatId, messages?.[0]);
        //   }
        // }

        // setMessages(messages || []);
      } catch (error) {
        showErrorNotification(error?.message || "Error when getting messages");
      }
    };

    if (selectedChat) {
      getMessages();
    }
  }, [selectedChat]);

  return (
    <section className={`chat-group ${selectedChat ? "active" : ""}`}>
      <div className="chat-main">
        <Conversation />
      </div>
      {/* <ListAttachmentFiles
        type="fixed"
        additionalProps={{ style: { width: 250, alignSelf: "start", marginTop: 20 } }}
      /> */}
    </section>
  );
};

export default ChatMain;
