import { useQuery } from "react-query";
import { getUserPayment } from "../../../../api/creditPayments";
import { formatPriceInUSD } from "../../../../utils/helpers";
import { formateDate } from "../../../../utils/dateutils";
import COLORS from "../../../../utils/constants/colors";
import { QUERY_KEYS } from "../../../../libs/react-query";
import { IconExternalLink, IconLoader2 } from "@tabler/icons-react";

export function PaymentHistory() {
  const { data: payments, isLoading: isLoadingPayments } = useQuery(
    QUERY_KEYS.USER_PAYMENTS,
    getUserPayment
  );

  const paymentsArray = Array.isArray(payments) ? payments : payments?.data || [];

  const sortedPayments = paymentsArray.slice().sort(
    (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
  );

  return (
    <div className="mt-5 d-flex flex-column gap-2">
      <h2>Invoice History</h2>
      {isLoadingPayments ? (
        <div className="mt-3 d-flex align-items-center justify-content-center">
          <IconLoader2 className="spin" />
        </div>
      ) : (
        <>
          <div
            className="scrollable-container"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {sortedPayments &&
              sortedPayments.length > 0 && sortedPayments.map(payment => (
                <div
                  key={payment.ID}
                  className="history-payments gap-3"
                  style={{
                    border: `1px solid ${COLORS.gray300}`,
                    borderRadius: 10,
                    padding: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <div className="d-flex flex-column flex-sm-row align-items-center gap-sm-3 gap-2">
                    <span>{formateDate(payment.UpdatedAt)}</span>
                    <span>{formatPriceInUSD(Number(payment.Amount))}</span>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minWidth: 90 }}
                  >
                    <span
                      className="text-center text-capitalize"
                      style={{
                        background:
                          payment.Status === "completed"
                            ? "#5cb85c"
                            : payment.Status === "pending"
                            ? COLORS.gray100
                            : "#f1404b",
                        color:
                          payment.Status === "pending"
                            ? COLORS.gray900
                            : "#fff",
                        padding: "6px 10px",
                        borderRadius: 8,
                        maxWidth: 100,
                        minWidth: 90,
                      }}
                    >
                      {payment.Status}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-end p-0 text-nowrap">
                    {payment.Method === "token" && (
                      <a
                        href={`https://basescan.org/tx/${payment.TransactionID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginRight: "16px" }}
                        className="small fw-semibold d-flex align-items-center gap-1"
                      >
                        View transaction <IconExternalLink size={16} />
                      </a>
                    )}
                    <span className="text-capitalize">
                      {payment.Method.replace("_", " ")}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
