import { SelectedChatDTO } from "../../@DTO/selectedChat";

export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_CHANNELS_AND_DOCUMENTS = "@@chats/GET_CHANNELS_AND_DOCUMENTS",
  GET_DOCUMENTS = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",

  CREATE_CHANNEL = "@@chats/CREATE_CHANNEL",
  CREATE_TOPIC_CHANNEL = "@@chats/CREATE_TOPIC_CHANNEL",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  GET_CHAT_USER_DETAILS = "@@chats/GET_CHAT_USER_DETAILS",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  HANDLE_CHAT_USER_CONVERSATIONS = "@@chats/HANDLE_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",
  GET_CHANNEL_JOB_STATUS = "@@chats/GET_CHANNEL_JOB_STATUS",
  GET_CHANNEL_JOB_STATUS_RESET = "@@chats/GET_CHANNEL_JOB_STATUS_RESET",
  CREATE_YOUTUBE_CHANNEL = "@@chats/CREATE_YOUTUBE_CHANNEL",
  GET_CHANNEL_DOCUMENTS_URLS = "@@chats/GET_CHANNEL_DOCUMENTS_URLS",

  // MESSAGE
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  GET_CHANNEL_DETAILS = "@@chats/GET_CHANNEL_DETAILS",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",
  REPLY_IMAGE = "@@chats/REPLY_IMAGE",
  CREATE_DOCUMENT_EMBEDDING = "@@chats/CREATE_DOCUMENT_EMBEDDING",
  CREATE_DOCUMENT_EMBEDDING_UPLOAD = "@@chats/CREATE_DOCUMENT_EMBEDDING_UPLOAD",
  CREATE_DOCUMENT_EMBEDDING_VIDEO = "@@chats/CREATE_DOCUMENT_EMBEDDING_VIDEO",
  CHANGE_ASSISTANT = "@@chats/CHANGE_ASSISTANT",
  CHANGE_TOPIC = "@@chats/CHANGE_TOPIC",
  GET_CHAT_TOPIC_CHANNELS = "@@chats/GET_CHAT_TOPIC_CHANNELS",
}
export interface ChatsState {
  favourites: Array<any>;
  directMessages: Array<any>;
  channels: Array<any>;
  selectedChat: SelectedChatDTO | null;
  chatUserDetails: object;
  chatUserConversations: {};
  isReceivingMessage: boolean;
  isOpenUserDetails: boolean;
  channelDetails: object;
  channelsAndDocuments: any;
  topicChannels: Array<any>;
  channelJobs: Array<any>;
  channelDocumentsUrls: Array<any>;
  createDocumentFileUploaded: boolean;
  planLimit: boolean;
  planLimitErrorType: string;
  channelDocumentsUrlsUploaded: boolean;
}
