export const ChatMenuPopUpI18n = {
  "pt-BR": {
    menu: {
      export: "Exportar PDF",
      rename: "Renomear",
      clear: "Limpar",
      "invite-member": "Convidar membro",
      delete: "Excluir",
    },
    notifications: {
      delete: {
        confirm: "Tem certeza de que deseja excluir este canal?",
        deleting: "Excluindo o canal e seu conteúdo, aguarde...",
      },
      clear: {
        confirm: "Tem certeza de que deseja limpar este canal?",
        cleaning: "Limpando o canal e seu conteúdo, aguarde...",
      },
      export: "Exportando o PDF do chat, aguarde...",
    },
  },
  "en-US": {
    menu: {
      export: "Export PDF",
      rename: "Rename",
      clear: "Clear",
      "invite-member": "Invite member",
      delete: "Delete",
    },
    notifications: {
      delete: {
        confirm: "Are you sure you want to delete this channel?",
        deleting: "Deleting the channel and its content, please wait...",
      },
      clear: {
        confirm: "Are you sure you want to clear this channel?",
        cleaning: "Clearing the channel and its content, please wait...",
      },
      export: "Exporting Chat PDF, please wait...",
    },
  },
  "es-ES": {
    menu: {
      export: "Exportar PDF",
      rename: "Renombrar",
      clear: "Limpiar",
      "invite-member": "Invitar miembro",
      delete: "Eliminar",
    },
    notifications: {
      delete: {
        confirm: "¿Estás seguro de que deseas eliminar este canal?",
        deleting: "Eliminando el canal y su contenido, por favor espera...",
      },
      clear: {
        confirm: "¿Estás seguro de que deseas limpiar este canal?",
        cleaning: "Limpiando el canal y su contenido, por favor espera...",
      },
      export: "Exportando el PDF del chat, por favor espera...",
    },
  },
  "fil-PH": {
    menu: {
      export: "I-export ang PDF",
      rename: "Palitan ang pangalan",
      clear: "Linisin",
      "invite-member": "Imbitahan ang miyembro",
      delete: "Burahin",
    },
    notifications: {
      delete: {
        confirm: "Sigurado ka bang nais mong burahin ang channel na ito?",
        deleting:
          "Binubura ang channel at ang laman nito, mangyaring maghintay...",
      },
      clear: {
        confirm: "Sigurado ka bang nais mong linisin ang channel na ito?",
        cleaning:
          "Nililinis ang channel at ang laman nito, mangyaring maghintay...",
      },
      export: "Ini-e-export ang PDF ng Chat, mangyaring maghintay...",
    },
  },
};
