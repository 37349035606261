import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";

const SkeletonMessage = ({ quantity = 5 }) => {
  const configsMainChat = useGetConfigsMainChat();
  const avatarAssistant = configsMainChat?.assistant?.profileImage;

  return (
    <div className="d-flex gap-3">
      <img
        src={avatarAssistant}
        alt=""
        width={32}
        height={32}
        style={{ borderRadius: "50%" }}
      />
      <ul className="skeleton-loader">
        {[...Array(quantity)].map((_, index) => (
          <li key={index} className="retangle">
            <Skeleton
              height={24}
              baseColor={"#e4e4e7"}
              highlightColor={"#F1F1F1"}
              duration={1}
              delay={index * 100}
              className="mb-2"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkeletonMessage;
