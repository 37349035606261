const ButtonDefaultAccount = ({
  children,
  border,
  width,
  onClick,
  icon,
  iconType,
  textButton,
  textAlign,
  loading,
  loadingIcon,
  disabled = false,
}) => {
  return (
    <div
      className={`button-default-account ${border ? "border" : ""}`}
      style={{ width }}
    >
      {children}
      {loading ? (
        <button
          style={{ textAlign: textAlign }}
          className="one-line"
          disabled={disabled || loading}
        >
          {loadingIcon}
        </button>
      ) : (
        <button
          style={{ textAlign: textAlign }}
          className="one-line"
          onClick={!disabled ? onClick : undefined}
          disabled={disabled}
        >
          {textButton}
        </button>
      )}
      {icon && iconType}
    </div>
  );
};

export default ButtonDefaultAccount;
