import { useDispatch, useSelector } from "react-redux";
import ButtonType1 from "../../Buttons/ButtonType1";
import {
  setOpenLeftBarMobile,
  setOpenLogin,
} from "../../../redux/general/action";
import { useGetIsLogged } from "../../../hooks/smallHooks";
import { useState } from "react";
import { IconMenu2 } from "@tabler/icons-react";
import { useUser } from "@supabase/auth-helpers-react";
import MenuNewLayout from "../MenuNewLayout";
import MenuWalletNewLayout from "../MenuWalletNewLayout";

const Account = ({ publishAssistant, onClick }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const isLogged = useGetIsLogged();
  const user = useUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = window.matchMedia("(max-width: 991.92px)").matches;
  const namePublish = window.matchMedia("(max-width: 460.92px)").matches;

  const leftBar = state?.configsGeneralReducer?.openLeftBarMobile;

  return (
    <div className={"account-group"}>
      {isLogged ? (
        <>
          <div className="btn-publish d-flex align-items-center">
            {publishAssistant && (
              <ButtonType1
                text={namePublish ? "Create" : "Publish Assistants"}
                variation="primary"
                props={{
                  style: {
                    padding: "0 16px",
                    borderRadius: 10,
                    marginRight: 12,
                  },
                  onClick: onClick,
                }}
              />
            )}
            {localStorage.getItem("webModeLogin") === "Web 2" ? (
              <MenuNewLayout
                toggle={() => setDropdownOpen(!dropdownOpen)}
                user={user}
                dropdownOpen={dropdownOpen}
              />
            ) : (
              <MenuWalletNewLayout
                toggle={() => setDropdownOpen(!dropdownOpen)}
                user={user}
                dropdownOpen={dropdownOpen}
              />
            )}
            {isMobile && (
              <button
                className="box-account d-flex"
                style={{ marginLeft: 12 }}
                onClick={() => {
                  dispatch(setOpenLeftBarMobile(!leftBar));
                }}
              >
                <span>Menu</span>
                <IconMenu2 stroke={1} size={14} />
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="d-flex align-items-center">
          <ButtonType1
            text={"Sign In"}
            variation="primary"
            props={{
              className: "btn-login d-flex align-items-center",
              onClick: () => dispatch(setOpenLogin(true)),
            }}
          />
          {isMobile && (
            <button
              className="box-account d-flex"
              style={{ marginLeft: 12 }}
              onClick={() => {
                dispatch(setOpenLeftBarMobile(!leftBar));
              }}
            >
              <span>Menu</span>
              <IconMenu2 stroke={1} size={14} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Account;
