import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultConfigsMainChat, {
  KEYS_MAIN_CHAT,
  LIST_TYPE_CHAT,
} from "../../services/chat/defaultConfigsMainChat";
import {
  setConfigsMainChat,
  setListAssistants,
  setListMachines,
  setListTypeChats,
  setWebModeLogin,
} from "../../redux/general/action";
import { getAssistantsPreview } from "../../api/assistants";
import { useGetConfigsMainChat } from "../../hooks/smallHooks";
import { IconChevronDown } from "@tabler/icons-react";
import { getModels } from "../../api";
import { useGetIsLogged } from "../../hooks/smallHooks";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import ChatMain from "../../components/Pages/PageChat/Chats/ChatMain/ChatMain";
import InputMainChat from "../../components/Pages/PageChat/InputMainChat/InputMainChat";
import GroupLogin from "../../components/Login/GroupLogin";
import { PreviewAttachmentFiles } from "../../components/Pages/PageChat/ListAttachmentFiles/PreviewAttachmentFiles";

const Chat = () => {
  const dispatch = useDispatch();
  const configsMainChat = useGetConfigsMainChat();
  const state = useSelector(state => state);
  const [openPreviews, setOpenPreviews] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const isLogged = useGetIsLogged();

  const defaultMachine = {
    id: "meta-llama/llama-3.1-8b-instruct:free",
    name: "Meta: Llama 3.1 8B Instruct (free)",
    description:
      "Meta's latest class of model (Llama 3.1) launched with a variety of sizes & flavors. This 8B instruct-tuned version is fast and efficient.\n\nIt has demonstrated strong performance compared to leading closed-source models in human evaluations.\n\nTo read more about the model release, [click here](https://ai.meta.com/blog/meta-llama-3/). Usage of this model is subject to [Meta's Acceptable Use Policy](https://llama.meta.com/llama3/use-policy/).",
    architecture: {
      instruct_type: "llama3",
      modality: "text->text",
      tokenizer: "Llama3",
    },
    pricing: {
      request: "0",
      prompt: "0",
      completion: "0",
      image: "0",
    },
  };

  useEffect(() => {
    // TODO: remove this
    const fetchAssistantsPreview = async () => {
      const res = await getAssistantsPreview();
      dispatch(setListAssistants(res.aizzyAssistants));
    };

    fetchAssistantsPreview();


    if (isLogged) {
      const fetchModelsAndUpdateState = async () => {
        try {
          const res = await getModels();
          const models = res.data;
          dispatch(setListMachines(models));
        } catch (error) {
          console.error("Erro ao obter modelos:", error);
        }
      };
      fetchModelsAndUpdateState();
    } else {
      dispatch(setListMachines([defaultMachine]))
    }
    dispatch(setListTypeChats(LIST_TYPE_CHAT));
    dispatch(
      setWebModeLogin(
        localStorage.getItem("webModeLogin") === null
          ? "Web 2"
          : localStorage.getItem("webModeLogin")
      )
    );
  }, [isLogged]);

  useEffect(() => {
    const initializeConfigsMainChat = () => {
      const { listAssistants, listMachines, listTypeChats } =
        state?.configsGeneralReducer;

      if (
        !localStorage.getItem("configsMainChat") &&
        listAssistants &&
        listMachines
      ) {
        const initialConfig = defaultConfigsMainChat({
          [KEYS_MAIN_CHAT.assistant]: listAssistants[0],
          [KEYS_MAIN_CHAT.machine]: defaultMachine?.id,
          [KEYS_MAIN_CHAT.chatData]: null,
          [KEYS_MAIN_CHAT.typeChat]: listTypeChats[0],
          [KEYS_MAIN_CHAT.enableWebSearch]: false,
        });

        localStorage.setItem("configsMainChat", JSON.stringify(initialConfig));
        dispatch(setConfigsMainChat(localStorage.getItem("configsMainChat")));
      }
    };

    initializeConfigsMainChat();
  }, [
    state?.configsGeneralReducer,
    state?.configsGeneralReducer.listAssistants,
    state?.configsGeneralReducer.listMachines,
    state?.configsGeneralReducer.listTypeChats,
  ]);

  const { openLeftBar, openLogin } = state?.configsGeneralReducer || {};

  const handleEmpty = isEmpty => {
    setShowButton(!isEmpty);
  };

  return (
    <section className={`main-layout ${!openLeftBar ? "maximize" : ""}`}>
      {!configsMainChat?.chatData && <h1>Chat Anonymously</h1>}
      <MenuTopBar />
      <ChatMain />
      <div className="p-3 gap-2 d-flex flex-column opened-chat input-main-chat">
        {showButton && (
          <button
            className="btn-open-preview"
            onClick={() => setOpenPreviews(!openPreviews)}
          >
            <IconChevronDown
              style={{
                width: 16,
                height: 16,
                transition: "all.2s",
                transform: `rotate(${openPreviews ? 0 : 180}deg)`,
              }}
            />
          </button>
        )}
        {openPreviews && <PreviewAttachmentFiles onEmpty={handleEmpty} />}
        <InputMainChat />
      </div>
      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default Chat;
