import { ChatsActionTypes, ChatsState } from "./types";

export const INIT_STATE: ChatsState = {
  favourites: [],
  directMessages: [],
  channels: [],
  selectedChat: null,
  chatUserDetails: {},
  chatUserConversations: {},
  isReceivingMessage: false,
  isOpenUserDetails: false,
  channelDetails: {},
  channelsAndDocuments: null,
  topicChannels: [],
  channelJobs: [],
  channelDocumentsUrls: [],
  createDocumentFileUploaded: false,
  planLimit: false,
  planLimitErrorType: "",
  channelDocumentsUrlsUploaded: false,
};

const Chats = (state = INIT_STATE, action: any) => {
  state = {
    ...state,
    planLimit: false,
    planLimitErrorType: "",
    channelDocumentsUrlsUploaded: false,
  };
  switch (action.type) {
    case ChatsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS:
          return {
            ...state,
            channelDocumentsUrlsLoading: false,
            channelDocumentsUrls: action.payload.data,
          };
        case ChatsActionTypes.CREATE_YOUTUBE_CHANNEL:
          return {
            ...state,
            createYoutubeChannelLoading: false,
            isYoutubeChannelCreated: true,
            isChannelCreated: true,
          };
        case ChatsActionTypes.GET_CHANNEL_JOB_STATUS:
          return {
            ...state,
            channelJobs: action.payload.data,
            channelJobsLoading: false,
          };
        case ChatsActionTypes.CREATE_TOPIC_CHANNEL:
          const { response, channelData } = action.payload.data;
          const _channel = {
            _id: response.channelId,
            userId: response.user,
            channelName: channelData.subject,
            assistantId: response.assistantId,
            topicId: channelData.topicId,
            isDocument: false,
          };
          return {
            ...state,
            selectedChat: _channel,
            topicChannels: [_channel, ...state.topicChannels],
            creatingTopicChannel: false,
          };
        case ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS:
          return {
            ...state,
            topicChannels: action.payload.data,
            getChatTopicChannelsLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS:
          // @ts-ignore
          if (state?.selectedChat) {
            // @ts-ignore
            state.selectedChat = action?.payload?.data?.[
              // @ts-ignore
              state?.selectedChat?.isDocument ? "documents" : "channels"
            ]?.find(
              // @ts-ignore
              doc => doc._id === state?.selectedChat?._id
            );
          }
          return {
            ...state,
            channelsAndDocuments: action.payload.data,
            isChannelsAndDocumentsFetched: true,
            getChannelsAndDocumentsLoading: false,
          };
        case ChatsActionTypes.GET_DOCUMENTS:
          return {
            ...state,
            favourites: action.payload.data,
            isFavouritesFetched: true,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            directMessages: action.payload.data,
            isDirectMessagesFetched: true,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            selectedChat: action.payload.data,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          let { chats_by_channel_and_assistant_id } = action.payload.data;
          let _channelMessages: any = [];
          for (let key in chats_by_channel_and_assistant_id) {
            for (
              let i = 0;
              i < chats_by_channel_and_assistant_id[key].length;
              i++
            ) {
              const _messageObj = chats_by_channel_and_assistant_id[key][i];
              if (_messageObj.chat_response.content) {
                _channelMessages = [_messageObj, ..._channelMessages];
              }
            }
          }
          return {
            ...state,
            chatUserConversations: { messages: _channelMessages },
            isUserConversationsFetched: true,
            getUserConversationsLoading: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.RECEIVE_MESSAGE:
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            isReceivingMessage: action.payload.data.isReceivingMessage,
            chatUserConversations: state.chatUserConversations["messages"]
              ? {
                  ...state.chatUserConversations,
                  messages: action.payload.data.messages.reduce(
                    (acc, newMessage) => {
                      const existingMessageIndex = acc.findIndex(
                        existingMessage =>
                          existingMessage.mId === newMessage.mId
                      );
                      if (
                        existingMessageIndex !== -1 &&
                        newMessage.mId !== undefined
                      ) {
                        if (newMessage.message) {
                          const lastMessage = newMessage.message.data;

                          acc[acc.length - 1] = lastMessage;
                        } else {
                          acc[existingMessageIndex].chat_response.content =
                            newMessage.chat_response.content;
                        }
                      } else {
                        acc.push(newMessage);
                      }
                      return acc;
                    },
                    [...state.chatUserConversations["messages"]]
                  ),
                }
              : action.payload.data,
          };

        case ChatsActionTypes.READ_MESSAGE:
          return {
            ...state,
            isMessageRead: true,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: true,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: true,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: true,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            chatUserDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING:
          return {
            ...state,
            createDocumentEmbeddingLoading: false,
            createDocumentEmbeddingPayload: null,
            channelDocumentsUrlsUploaded: true,
          };
        case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD:
          return {
            ...state,
            createDocumentEmbeddingLoading: false,
            createDocumentEmbeddingUploadLoading: false,
            createDocumentFileUploaded: true,
            planLimit: false,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case "planLimitError":
          return {
            ...state,
            planLimit: action.payload.error.planLimit,
            planLimitErrorType: action.payload.error.planLimitErrorType,
          };
        case ChatsActionTypes.CREATE_YOUTUBE_CHANNEL:
          return {
            ...state,
            createYoutubeChannelLoading: false,
            isYoutubeChannelCreated: false,
            isChannelCreated: false,
          };
        case ChatsActionTypes.GET_CHANNEL_JOB_STATUS:
          return {
            ...state,
            channelJobsLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS:
          return {
            ...state,
            isChannelsAndDocumentsFetched: false,
            getChannelsAndDocumentsLoading: false,
          };
        case ChatsActionTypes.GET_DOCUMENTS:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            selectedChat: null,
            isChannelCreated: false,
            createChannelLoading: false,
            createYoutubeChannelLoading: false,
            isYoutubeChannelCreated: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: state.chatUserConversations,
            isUserConversationsFetched: false,
            getUserConversationsLoading: false,
          };
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            isReceivingMessage: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
            isReceivingMessage: false,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: false,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: false,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING:
          return {
            ...state,
            createDocumentEmbeddingLoading: false,
            planLimit: action.payload.error.planLimit,
            planLimitErrorType: action.payload.error.code,
          };
        case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD:
          return {
            ...state,
            createDocumentEmbeddingLoading: false,
            createDocumentEmbeddingUploadLoading: false,
            createDocumentFileUploaded: false,
            planLimit: action.payload.error.planLimit,
            planLimitErrorType: action.payload.error.code,
          };
        default:
          return { ...state };
      }
    case ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS:
      return {
        ...state,
        getChannelsAndDocumentsLoading: true,
        isChannelsAndDocumentsFetched: false,
      };
    case ChatsActionTypes.GET_DOCUMENTS: {
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    }
    case ChatsActionTypes.GET_DIRECT_MESSAGES:
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
      };
    case ChatsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
        isUserMessageSent: false,
        isReceivingMessage: false,
        chatUserConversations: [],
        channelDocumentsUrls: [],
        channelJobs: [],
      };
    case ChatsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        chatUserConversations: [],
        isUserConversationsFetched: false,
        getUserConversationsLoading: true,
      };
    case ChatsActionTypes.HANDLE_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        chatUserConversations: action.payload,
      };
    case ChatsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case ChatsActionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        isUserMessageSent: true,
      };
    case ChatsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        isMessageDeleted: false,
      };
    case ChatsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case ChatsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING:
      return {
        ...state,
        createDocumentEmbeddingLoading: true,
        createDocumentEmbeddingPayload: action.payload,
      };
    case ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD:
      return {
        ...state,
        createDocumentEmbeddingLoading: true,
        createDocumentEmbeddingUploadLoading: true,
        createDocumentFileUploaded: false,
        planLimit: false,
      };
    case ChatsActionTypes.CHANGE_ASSISTANT:
      return {
        ...state,
        assistant: action.payload,
      };
    case ChatsActionTypes.CHANGE_TOPIC:
      return {
        ...state,
        topic: action.payload,
        topicChannels: null,
      };
    case ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS:
      return {
        ...state,
        getChatTopicChannelsLoading: true,
      };
    case ChatsActionTypes.CREATE_TOPIC_CHANNEL:
      return {
        ...state,
        selectedChat: null,
        creatingTopicChannel: true,
      };
    case ChatsActionTypes.GET_CHANNEL_JOB_STATUS:
      return {
        ...state,
        channelJobsLoading: true,
      };
    case ChatsActionTypes.GET_CHANNEL_JOB_STATUS_RESET:
      return {
        ...state,
        channelJobs: action.payload,
        channelJobsLoading: false,
      };
    case ChatsActionTypes.CREATE_YOUTUBE_CHANNEL:
      return {
        ...state,
        createYoutubeChannelLoading: true,
        isChannelCreated: false,
        isYoutubeChannelCreated: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS:
      return {
        ...state,
        channelDocumentsUrlsLoading: true,
        channelDocumentsUrls: [],
      };
    default:
      return { ...state };
  }
};

export default Chats;
