import { QueryClient } from "react-query";

export const queryClient = new QueryClient();

export const QUERY_KEYS = {
  WS_SITES: "ws-sites",
  COMMUNITY_ASSISTANTS: "community-assistants",
  AIZZY_ASSISTANTS: "aizzy-assistants",
  MY_ASSISTANTS: "my-assistants",
  EDIT_ASSISTANT: "edit-assistant",
  CHANNELS: "channels",
  DOCUMENTS: "documents",
  IMAGES_CHANNEL: "images-channel",
  MESSAGES: "messages",
  USER_CREDITS: "user-credits",
  USER_PAYMENTS: "user-payments",
};
