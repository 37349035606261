import { create } from "zustand";

interface UserState {
  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;
}

export const useUserStore = create<UserState>(set => ({
  isLogged: false,
  setIsLogged: isLogged => {
    set({ isLogged });
  },
}));
