import Tag from "../Add-ons/Tag";

const ButtonAsideType1 = ({
  tag = "button",
  children,
  dark = false,
  hover = false,
  props,
  onClick = () => {},
}) => {
  return (
    <Tag
      tag={tag}
      props={{
        ...props,
        onClick: onClick,
        className: `button-aside-type-1 ${dark ? "dark" : ""} ${
          hover ? "hover" : ""
        } ${props?.className}`,
      }}
    >
      {children}
    </Tag>
  );
};

export default ButtonAsideType1;
