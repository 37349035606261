import { APIClient } from "./apiCore";

const api = new APIClient();
const assistantsApi = "https://assistant-rs-b9f22b457296.herokuapp.com/v1";

const getAssistants = () => {
  return api.get(assistantsApi + "/assistant");
};

const getAssistantsPreview = () => {
  return api.get(process.env.REACT_APP_API_URL + "/api/assistant/preview");
};

const getAssistant = (assistantId: string) => {
  return api.get(assistantsApi + `/assistant?assistant_id=${assistantId}`);
};

const getFreeCommunityAssistants = ({ pageNumber = 0, language = "en" }) => {
  return api.get(
    `${assistantsApi}/assistants/community/free?page_number=${pageNumber}&lang=${language}`
  );
};

const getPaidCommunityAssistants = ({
  pageNumber = 0,
  pageSize = 10,
  language = "en",
}) => {
  return api.get(
    `${assistantsApi}/assistants/community/paid?page_number=${pageNumber}&page_size=${pageSize}&lang=${language}`
  );
};

const getMyAssistants = ({
  pageNumber = 0,
  pageSize = 10,
  language = "en",
}) => {
  return api.get(
    `${assistantsApi}/assistants/community/private?page_number=${pageNumber}&page_size=${pageSize}&lang=${language}`
  );
};

const getCommunityAssistantsByTerm = ({
  pageNumber = 0,
  searchTerm = "",
  language = "en",
}) => {
  return api.get(
    `${assistantsApi}/assistants/search?page_number=${pageNumber}&page_size=100&search_terms=${searchTerm}&lang=${language}`
  );
};

const getCommunityAssistantsSuggestions = ({
  pageNumber = 0,
  searchTerm = "",
  language = "pt",
}) => {
  return api.get(
    `${assistantsApi}/assistants/search/suggestions?page_number=${pageNumber}&page_size=1000&search_terms=${searchTerm}&lang=${language}`
  );
};

const getCompanyAssistants = ({ pageNumber = 0, pageSize = 40 }) => {
  return api.get(
    `${assistantsApi}/assistants/company?page_number=${pageNumber}&page_size=${pageSize}`
  );
};

const likeCommunityAssistant = (assistantId: string) => {
  return api.create(assistantsApi + "/assistants/likes", {
    assistantId,
  });
};

const unlikeCommunityAssistant = (assistantId: string) => {
  return api.delete(assistantsApi + "/assistants/likes", {
    assistantId,
  });
};

const createAssistant = (data: object) => {
  return api.create(process.env.REACT_APP_API_URL + "/api/assistant", data);
};

const getCommunityAssistant = (assistantId: string) => {
  return api.get(
    process.env.REACT_APP_API_URL + "/api/assistant/" + assistantId
  );
};

const getAssistantImages = () => {
  return api.get(assistantsApi + "/assistant/images");
};

const getAssistantImagesDefalut = () => {
  return api.get(process.env.REACT_APP_API_URL + "/api/assistant/images");
};

export {
  getAssistants,
  getAssistantsPreview,
  getAssistant,
  getFreeCommunityAssistants,
  getCommunityAssistant,
  getCompanyAssistants,
  getMyAssistants,
  getPaidCommunityAssistants,
  getCommunityAssistantsByTerm,
  getCommunityAssistantsSuggestions,
  likeCommunityAssistant,
  unlikeCommunityAssistant,
  createAssistant,
  getAssistantImages,
  getAssistantImagesDefalut
};
