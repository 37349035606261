import { BaseService } from "./base";

export const storeNames = {
  users: "users",
  assistants: "assistants",
  chats: "chats",
  documents: "documents",
};

interface ICompressedDocument {
  id: string;
  document: {
    postgresId: string;
    content: string;
  }[];
}

export class DocumentRepository extends BaseService {
  constructor() {
    super(storeNames.documents);
  }

  async saveDocument(documentId, document) {
    try {
      const exists = await this.get(documentId);

      if (exists) {
        return null;
      }

      return await this.db
        .table(this.storeName)
        .add({ id: documentId, document: document });
    } catch (error) {
      console.error(`Error saving document in ${this.storeName}:`, error);
      throw error;
    }
  }

  async getCompressedDocumentsByPostgresIds(postgresIds: string[]) {
    try {
      const documents: ICompressedDocument[] = await this.db
        .table(this.storeName)
        .toArray();

      const contents = documents
        .flatMap(document => document.document)
        .filter(data => postgresIds.includes(data?.postgresId))
        .map(data => {
          if (!data) {
            return null;
          }
          return {
            id: data.postgresId,
            content: data.content,
          };
        })
        .filter(item => item !== null);

      return contents || [];
    } catch (error: any) {
      console.error(
        `Erro ao buscar documentos por ids do Postgres em ${this.storeName}:`,
        error?.message
      );
      throw error;
    }
  }

  async deleteDocumentById(id: string) {
    try {
      await this.db.table(this.storeName).delete(id);
    } catch (error) {
      console.error(
        `Error deleting document with id ${id} from ${this.storeName}:`,
        error
      );
      throw error;
    }
  }
}

export function makeDocumentId(
  channelId: string,
  userId: string,
  jobId: string
) {
  return `${channelId}_${userId}_${jobId}`;
}
