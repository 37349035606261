import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import Tooltip from "../../../Add-ons/Tooltip";
import { setConfigsMainChat } from "../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";

const ChangeTypeChat = () => {
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  const configsMainChat = useGetConfigsMainChat();

  useEffect(() => {
    if (configsMainChat?.typeChat?.title !== "message") {
      const defaultMessageType = state?.configsGeneralReducer?.listTypeChats?.find(item => item.title === "message");
      if (defaultMessageType) {
        dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.typeChat, defaultMessageType));
      }
    }
  }, []);
  
  return (
    <div className="buttons-change-type-chat">
      {state?.configsGeneralReducer?.listTypeChats?.map(item => (
        <Tooltip key={item.title} text={item.description}>
          <button
            type="button"
            className={`${
              configsMainChat?.typeChat?.title === item.title ? "active" : ""
            }`}
            onClick={() =>
              dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.typeChat, item))
            }
          >
            {item?.icon}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default ChangeTypeChat;
