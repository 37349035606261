import { useDispatch, useSelector } from "react-redux";
import { setWebModeLogin } from "../../../redux/general/action";

const SwitchWebMode = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const options = ["Web 2", "Web 3"];

  return (
    <div className="switch-web-mode">
      {options.map((option, index) => (
        <button
          keya={index}
          className={
            state?.configsGeneralReducer?.webModeLogin === option
              ? "active"
              : ""
          }
          onClick={() => dispatch(setWebModeLogin(option))}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default SwitchWebMode;
