import { debounce } from "lodash";
import { memo, useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button, Collapse, Input } from "reactstrap";
import {
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
} from "../../services/notifications";
import useTranslation from "../../hooks/useTranslation";
import RemainingCallsContext from "../../freemium/RemainingCallsContext";
import { getCurrentUserPlan } from "../../services/userHelper";
import { PLANS_AIZZY } from "../../utils/constants/Plans";
import {
  createDocumentEmbeddingUpload,
  createWebsiteEmbeddings,
  createYoutubeVideoEmbeddings,
} from "../../api/embeddings";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
} from "../../hooks/smallHooks";
import { IconLoader2 } from "@tabler/icons-react";
import { queryClient } from "../../libs/react-query";
import { useDispatch } from "react-redux";
import { setConfigsMainChat } from "../../redux/general/action";
import {
  KEYS_MAIN_CHAT,
  LIST_TYPE_CHAT,
} from "../../services/chat/defaultConfigsMainChat";
import { ChatInputSectionI18n } from "../Pages/PageChat/Chats/ChatInputSection.i18n";
import { AttachDocumentMenuI18n } from "./AttachDocumentMenu.i18n";

interface AttachDocumentMenuProps {
  isOpen: boolean;
  onToggle: () => void;
  onAttachDocumentByUrl: (url: string) => void;
  onAttachDocumentFile: (file: any) => void;
}

const AttachDocumentMenu = ({ isOpen, onToggle }: AttachDocumentMenuProps) => {
  const { pdfSizeLimit, setShowFreemiumModal } = useContext(
    RemainingCallsContext
  );

  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showAttachByUrl, setShowAttachByUrl] = useState(false);
  const [url, setUrl] = useState<string>("");

  const { t } = useTranslation(AttachDocumentMenuI18n);

  const { chatData } = useGetConfigsMainChat() || {};

  const { handleCreateChannel } = useCreateNewChannel();
  const dispatch = useDispatch();

  const isPremiumUser = getCurrentUserPlan() === PLANS_AIZZY.PREMIUM;

  const typesSupported = [
    "pdf",
    "docx",
    "pptx",
    "odt",
    "odp",
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "gif",
    "tif",
    "tiff",
    "tex",
    "txt",
    "rtf",
  ];

  const createChannelIfNecessary = async () => {
    if (!chatData) {
      return await handleCreateChannel();
    }
    return chatData;
  };

  async function handleSubimtUrl(url: string) {
    if (isLoading) return;

    const currentChannel = await createChannelIfNecessary();

    try {
      setIsLoading(true);
      const isYoutubeUrl = /https?:\/\/(?:www\.)?youtube\.com\/[^\s]+/gi.test(
        url
      );

      if (isYoutubeUrl) {
        await createYoutubeVideoEmbeddings({
          url,
          channelId: currentChannel._id,
        });

        showSuccessNotification(
          "Youtube video sent successfully! Initializing processing..."
        );

        dispatch(
          setConfigsMainChat(
            KEYS_MAIN_CHAT.typeChat,
            LIST_TYPE_CHAT.find(item => item.title === "attachment")
          )
        );
        onToggle();
        return;
      }

      const isValidUrl =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w \.-]*)*\/?\??([^#\n\r]*)?#?([^#\n\r]*)?$/.test(
          url
        );

      if (!isValidUrl) {
        return showErrorNotification("Invalid URL");
      }

      await createWebsiteEmbeddings({
        url: url,
        channelId: currentChannel._id,
      });

      showSuccessNotification(
        "Website sent successfully! Initializing processing..."
      );

      dispatch(
        setConfigsMainChat(
          KEYS_MAIN_CHAT.typeChat,
          LIST_TYPE_CHAT.find(item => item.title === "attachment")
        )
      );

      onToggle();
    } catch (error: any) {
      showErrorNotification(error.message || "Error when sending content");
    } finally {
      await queryClient.invalidateQueries(["attachments", currentChannel._id]);
      setIsLoading(false);
    }
  }

  async function handleSubmitFile(file: File) {
    if (isLoading) return;

    const currentChannel = await createChannelIfNecessary();

    try {
      setIsLoading(true);
      setUploadProgress(0);
      showWarningNotification("We are loading the file...");

      await createDocumentEmbeddingUpload(
        currentChannel._id,
        file,
        (progress: number) => {
          console.log("progress", progress);
        }
      );

      showSuccessNotification(
        "Document sent successfully! Initializing processing..."
      );

      dispatch(
        setConfigsMainChat(
          KEYS_MAIN_CHAT.typeChat,
          LIST_TYPE_CHAT.find(item => item.title === "attachment")
        )
      );
      onToggle();
    } catch (error: any) {
      showErrorNotification(error.message || "Error when sending content");
    } finally {
      await queryClient.invalidateQueries(["attachments", currentChannel._id]);
      setIsLoading(false);
    }
  }

  const AttachByURL = () => (
    <div>
      <div>
        <h5 className="font-size-14 mb-3">{t("chat.label")}</h5>
      </div>
      <div className="d-flex align-items-center gap-3">
        <Input
          type="text"
          className="form-control form-control-lg chat-input"
          id="chat-input"
          placeholder={t("placeholder.main")}
          autoFocus
          defaultValue={url}
          onChange={debounce((e: any) => setUrl(e.target.value), 700)}
        />
        <Button
          color="primary"
          type="button"
          className="btn btn-primary chat-send waves-effect waves-light d-flex align-items-center justify-content-center"
          disabled={!url.trim() || isLoading}
          onClick={() => handleSubimtUrl(url.trim())}
        >
          {isLoading ? (
            <IconLoader2 className="spin" />
          ) : (
            <span>{t("button.label")}</span>
          )}
        </Button>
      </div>
    </div>
  );
  const SliderButtonOptions = () => {
    const { t } = useTranslation(ChatInputSectionI18n);

    return (
      <div className="d-flex flex-column align-items-center gap-2">
        <p className="text-center mb-3 fs-6">{t("chat.label")}</p>
        <div className="d-flex flex-column align-items-center gap-3">
          <FileUploader
            classes="dropzone"
            label={t("button.docs")}
            maxSize={pdfSizeLimit}
            handleChange={file => handleSubmitFile(file)}
            onSizeError={() => {
              if (!isPremiumUser) {
                setShowFreemiumModal("pdfSize");
              } else {
                showErrorNotification(
                  t("notifications.error", { pdfSizeLimit }),
                  {
                    duration: 5000,
                  }
                );
              }
            }}
            name="file"
            types={typesSupported}
          />
          {isLoading && (
            <div className="d-flex flex-column align-items-center gap-3 w-100">
              <IconLoader2 size={30} className="spin" />
              {/* <ProgressBar value={uploadProgress} /> */}
            </div>
          )}
        </div>
        {!isLoading && (
          <>
            <div className="my-3 d-flex flex-column align-items-center">
              <h5>{t("docs.label")}</h5>
              <ul
                className="list-unstyled sm row d-flex justify-content-center align-items-center gap-2"
                style={{
                  maxWidth: 600,
                }}
              >
                {typesSupported.map(type => (
                  <li
                    className="mt-2 col-2 text-center  py-2"
                    key={type}
                    style={{
                      width: 55,
                    }}
                  >
                    {type}
                  </li>
                ))}
              </ul>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary font-size-13"
              onClick={() => setShowAttachByUrl(!showAttachByUrl)}
            >
              <i className="bx bx-link-alt me-2"></i>
              {t("button.link")}
            </button>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    setUrl("");
  }, [isOpen]);
  return (
    <Collapse
      isOpen={isOpen}
      className="chat-input-collapse"
      id="chatinputmorecollapse"
    >
      {isOpen && (
        <div className="d-flex flex-column">
          <div>
            {!showAttachByUrl ? <SliderButtonOptions /> : <AttachByURL />}
          </div>
        </div>
      )}
    </Collapse>
  );
};

export default memo(AttachDocumentMenu);
