import { useLocation } from "react-router-dom";
import TitleTopBar from "../Pages/PageChat/Chats/ToolsTopBar/TitleTopBar";
import Account from "./Account/Account";
import { useGetConfigsMainChat } from "../../hooks/smallHooks";

const MenuTopBar = ({ publishAssistant, onClick }) => {
  const configsMainChat = useGetConfigsMainChat();
  const location = useLocation();

  return (
    <nav className="menu-top-bar">
      {configsMainChat?.chatData?.channelName &&
        location.pathname !== "/assistants" &&
        location.pathname !== "/settings" && (
          <TitleTopBar configsMainChat={configsMainChat} />
        )}
      <Account publishAssistant={publishAssistant} onClick={onClick} />
    </nav>
  );
};

export default MenuTopBar;
