import { AES, enc } from "crypto-js";

interface ICryptService {
  encrypt(text: string): string;
  decrypt(encryptedText: string): string;
}

class CryptService implements ICryptService {
  private cryptoKey: string;

  constructor() {
    // TODO: Remove the hardcoded key and use a secure way to store the key
    this.cryptoKey =
      process.env.REACT_APP_AES_KEY ||
      "IUzI1NR4xK8OucyJpc3MiOiJzdXBhqF79K09HNQ0aXncoglICD7ZusCaa";
  }

  encrypt(data: string): string {
    const encryptedData = AES.encrypt(data, this.cryptoKey).toString();
    return encryptedData;
  }

  decrypt(encryptedData: string): string {
    const decryptedData = AES.decrypt(encryptedData, this.cryptoKey).toString(
      enc.Utf8
    );
    return decryptedData;
  }
}

export const cryptoService = new CryptService();
