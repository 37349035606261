import { APIClient } from "./apiCore";
import * as url from "./urls";
import detectBrowserLanguage from "detect-browser-language";

const api = new APIClient();

const getProfileDetails = () => {
  const lang = detectBrowserLanguage();
  let url = "/api/user";

  if (lang) url += "?lang=" + lang;
  return api.get(process.env.REACT_APP_API_URL + url);
};

const getSettings = () => {
  return api.get(url.GET_USER_SETTINGS);
};

const updateSettings = (field: string, value: any) => {
  return api.update(url.UPDATE_ETTINGS, {
    field: field,
    value: value,
  });
};

const getAffiliateProgramReport = () => {
  return api.get(
    process.env.REACT_APP_API_URL + "/api/user/referral/subscriptions/report"
  );
};

const updateCompanyName = data => {
  return api.create(
    process.env.REACT_APP_API_URL + "/api/user/update-company-info",
    data
  );
};

const gerReferral = referral => {
  return api.get(
    `${process.env.REACT_APP_API_URL}/api/user/referral/${referral}`
  );
};

const updateReferral = referralId => {
  return api.update(`${process.env.REACT_APP_API_URL}/api/user/referral`, {
    referralId,
  });
};

const deleteAccount = userId => {
  return api.delete(`${process.env.REACT_APP_API_URL}/api/user/account`, {
    userId,
  });
};

const submitPhoneNumber = phone => {
  return api.update(`${process.env.REACT_APP_API_URL}/api/user/phone_number`, {
    phone_number: phone,
  });
};

export {
  getProfileDetails,
  getSettings,
  updateSettings,
  updateCompanyName,
  getAffiliateProgramReport,
  gerReferral,
  updateReferral,
  deleteAccount,
  submitPhoneNumber,
};
