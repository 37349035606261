import {
  IconCoins,
  IconHeadset,
  IconShield,
  IconTrendingUp,
  IconUserCircle,
} from "@tabler/icons-react";
import AffiliationSettings from "../../components/Pages/SettingsNewLayout/AffiliationSettings";
import GeneralSettings from "../../components/Pages/SettingsNewLayout/GeneralSettings";
import PlanSettings from "../../components/Pages/SettingsNewLayout/PlanSettings";
import SecuritySettings from "../../components/Pages/SettingsNewLayout/SecuritySettings";
import SupportSettings from "../../components/Pages/SettingsNewLayout/SupportSettings";

const settings = [
  {
    id: "general",
    icon: <IconUserCircle style={{ width: 16, height: 16 }} />,
    label: "General",
    content: <GeneralSettings />,
  },
  {
    id: "security",
    icon: <IconShield style={{ width: 16, height: 16 }} />,
    label: "Security",
    content: <SecuritySettings />,
  },
  {
    id: "credits",
    icon: <IconCoins style={{ width: 16, height: 16 }} />,
    label: "Credits",
    content: <PlanSettings />,
  },
  {
    id: "affiliation",
    icon: <IconTrendingUp style={{ width: 16, height: 16 }} />,
    label: "Affiliation",
    content: <AffiliationSettings />,
  },
  {
    id: "support",
    icon: <IconHeadset style={{ width: 16, height: 16 }} />,
    label: "Support",
    content: <SupportSettings />,
  },
];

export default settings;
