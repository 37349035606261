import ButtonType1 from "../../Buttons/ButtonType1";
import google from "../../../assets/images/icons/google.svg";

const Web2Login = ({
  isLoading = false,
  isLoadingGoogle = false,
  handleLogin = () => { },
  handleSocialLogin = () => { },
  onChangeEmail = () => { },
  onChangePassword = () => { },
}) => {
  return (
    <form
      action=""
      onSubmit={e => {
        e.preventDefault();
        handleLogin();
      }}
      className="form-login-web2"
    >
      <div className="group">
        <label htmlFor="email">E-mail</label>
        <input
          type="text"
          name="email"
          id="email"
          required
          onChange={onChangeEmail}
          placeholder="Your email address"
        />
      </div>
      <div className="group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          onChange={onChangePassword}
          name="password"
          id="password"
          required
          placeholder="Your password"
        />
      </div>
      <ButtonType1
        text={"Continue"}
        isLoading={isLoading}
        variation="primary"
        props={{
          className: "btn-main-form",
          type: "submit",
        }}
      />
      <div className={"divider"}>OR</div>
      <ButtonType1
        isLoading={isLoadingGoogle}
        icon={google}
        text={"Sign in with Google"}
        variation="thirty"
        props={{
          className: "btn-socials",
          type: "button",
          onClick: handleSocialLogin,
        }}
      />
    </form>
  );
};

export default Web2Login;
