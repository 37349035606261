import { IconEdit, IconX } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import { Select } from "antd";
import IconArrowDown from "../../assets/images/icons-new-layout/arrow-down.svg";
import ButtonType1 from "../Buttons/ButtonType1";
import { useEffect, useState } from "react";
import { createAssistant, getCommunityAssistant } from "../../api/assistants";
import { QUERY_KEYS, queryClient } from "../../libs/react-query";
import { useQuery } from "react-query";

const ModalEditAssistant = ({
  selectedAssistant,
  setOpenModal,
  handleGenerateImage,
  setModalEditImage,
  openModal,
}) => {
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [role, setRole] = useState("");
  const [category, setCategory] = useState("");
  const [enabled, setEnabled] = useState("");
  const [isPrivate, setIsPrivate] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [prompt, setPrompt] = useState("");
  const [defaultGreetings, setDefaultGreetings] = useState("");
  const [language, setLanguage] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { data: assistant } = useQuery(
    [QUERY_KEYS.EDIT_ASSISTANT, selectedAssistant?._id],
    async () => {
      return await getCommunityAssistant(selectedAssistant?._id);
    },
    {
      enabled: !!selectedAssistant?._id,
      keepPreviousData: true,
    }
  );

  const selectPrivate = value => {
    if (value === "Privado") {
      setIsPrivate(true);
    } else if (value === "Público") {
      setIsPrivate(false);
    }
  };

  const handleActiveChange = value => {
    if (value === "Sim") {
      setEnabled(true);
    } else if (value === "Não") {
      setEnabled(false);
    }
  };

  const handleCategoryChange = value => {
    setCategory(value);
  };

  const handleLanguageChange = value => {
    setLanguage(value);
  };

  const resetValue = () => {
    setName(assistant?.name || "");
    setAbout(assistant?.about || "");
    setRole(assistant?.role || "");
    setCategory(assistant?.category || "");
    setEnabled(assistant?.enabled || "");
    setIsPrivate(assistant?.private || "");
    setProfileImage(assistant?.profileImage || "");
    setPrompt(assistant?.prompt || "");
    setDefaultGreetings(assistant?.defaultGreetings || "");
    setLanguage(assistant?.language || "");
    setKnowledge(assistant?.knowledge || "");
    setOpenModal(false);
  };


  useEffect(() => {
    setName(assistant?.name);
    setAbout(assistant?.about);
    setRole(assistant?.role);
    setCategory(assistant?.category);
    setEnabled(assistant?.enabled);
    setIsPrivate(assistant?.private);
    setProfileImage(assistant?.profileImage);
    setPrompt(assistant?.prompt);
    setDefaultGreetings(assistant?.defaultGreetings);
    setLanguage(assistant?.language);
    setKnowledge(assistant?.knowledge);
    console.log(assistant);
  }, [assistant]);

  const dataNewAssistants = {
    languages: [],
    category: category,
    private: isPrivate,
    enabled: enabled,
    defaultGreetings: defaultGreetings,
    profileImage: profileImage,
    about: about,
    prompt: prompt,
    role: role,
    language: language,
    name: name,
    userName: name,
    _id: selectedAssistant?._id,
  };

  const handleUpdateAssistant = async () => {
    try {
      setIsLoading(true);
      const response = await createAssistant(dataNewAssistants);
      setOpenModal(false);
      setIsLoading(false);
      await queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
    } catch (error) {
      console.log(error);
      setOpenModal(false);
    }
  };

  const checkIfFormIsDirty = () => {
    return (
      name !== assistant?.name ||
      about !== assistant?.about ||
      role !== assistant?.role ||
      category !== assistant?.category ||
      enabled !== assistant?.enabled ||
      isPrivate !== assistant?.private ||
      profileImage !== assistant?.profileImage ||
      prompt !== assistant?.prompt ||
      defaultGreetings !== assistant?.defaultGreetings ||
      language !== assistant?.language
    );
  };

  return (
    <ModalGlobal
      open={openModal}
      showBtnClose={false}
      classNameChildren={`modal-create-assistants animate__animated animate__fadeInDown`}
    >
      <div className="w-100 d-flex justify-content-between align-items-center">
        <h4 className="m-0">Modal edit</h4>
        <button
          className="btn-icon-header"
          onClick={() => {
            setOpenModal(false);
            resetValue();
          }}
        >
          <IconX stroke={1.5} style={{ width: 16, height: 16 }} />
        </button>
      </div>
      <div
        className="review-publish-assistants w-100"
        style={{ paddingRight: "10px" }}
      >
        <div className="create-assistants-general-info">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Name</span>
            <div className="div-input-general-info">
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                maxLength={30}
              />
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Description</span>
            <div className="div-input-general-info">
              <input
                value={about}
                onChange={e => setAbout(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Role</span>
            <div className="div-input-general-info">
              <input
                type="text"
                value={role}
                onChange={e => setRole(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Category</span>
            <Select
              className="select-antd-create-assistant"
              suffixIcon={
                <img
                  src={IconArrowDown}
                  height={10}
                  width={10}
                  alt="Arrow down"
                />
              }
              size="large"
              showSearch
              filterOption={false}
              value={category === "" ? "Select category" : category}
              onChange={handleCategoryChange}
            >
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="category1"
              >
                Category 1
              </Select.Option>
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="category2"
              >
                Category 2
              </Select.Option>
            </Select>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Ativar assistente para uso?</span>
            <Select
              className="select-antd-create-assistant"
              suffixIcon={
                <img
                  src={IconArrowDown}
                  height={10}
                  width={10}
                  alt="Arrow down"
                />
              }
              size="large"
              showSearch
              filterOption={false}
              value={
                enabled === "" ? "Select" : enabled === true ? "Sim" : "Não"
              }
              onChange={handleActiveChange}
            >
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="Sim"
              >
                Sim
              </Select.Option>
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="Não"
              >
                Não
              </Select.Option>
            </Select>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Controle a visibilidade</span>
            <Select
              className="select-antd-create-assistant"
              suffixIcon={
                <img
                  src={IconArrowDown}
                  height={10}
                  width={10}
                  alt="Arrow down"
                />
              }
              size="large"
              showSearch
              filterOption={false}
              value={
                isPrivate === ""
                  ? "Select"
                  : isPrivate === true
                    ? "Privado"
                    : "Público"
              }
              onChange={selectPrivate}
            >
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="Público"
              >
                Público (Qualquer usuário poderá visualizá-lo)
              </Select.Option>
              <Select.Option
                style={{ display: "flex", alignItems: "center" }}
                value="Privado"
              >
                Privado (Somente você poderá visualizá-lo)
              </Select.Option>
            </Select>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-start">
            <span className="span">Avatar</span>
            <div className="select-avatar w-100 d-flex flex-column justify-content-start gap-2">
              <div
                style={{ width: 110, height: 40 }}
                className="d-flex justify-content-center align-items-center div-input-upload-avatar"
              >
                <div className="d-flex justify-content-between align-items-center w-100 px-2">
                  <img
                    src={profileImage}
                    alt=""
                    width={24}
                    height={24}
                    style={{ borderRadius: "50%" }}
                  />
                  <IconEdit
                    size={14}
                    stroke={1.5}
                    cursor={"pointer"}
                    onClick={() => {
                      setModalEditImage(true);
                      handleGenerateImage();
                    }}
                  />
                </div>
              </div>
              {/* <FileUploader
            classes="upload-img"
            name="Image"
            types={typesSupported}
          /> */}
              {/* <div
              style={{ width: 110 }}
              className="d-flex justify-content-center align-items-center div-input-upload-avatar"
            >
              <input
                type="text"
                placeholder="Upload"
                value={avatar}
              
              />
              <IconPlus />
            </div>
            <div className="d-flex gap-2 align-items-center">
              <Checkbox style={{ height: 15 }} />
              <span className="span">Generate with AI</span>
            </div> */}
            </div>
          </div>
        </div>
        <div className="create-assistants-data">
          <div className="d-flex w-100 justify-content-between align-items-start">
            <span className="span">Prompt</span>
            <div className="textarea-data">
              <textarea value={prompt} onChange={e => setPrompt(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-start">
            <span className="span">Default Greetings</span>
            <div className="textarea-data">
              <textarea value={defaultGreetings} onChange={e => setDefaultGreetings(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span className="span">Language</span>
            <Select
              className="select-antd-create-assistant"
              suffixIcon={
                <img
                  src={IconArrowDown}
                  height={10}
                  width={10}
                  alt="Arrow down"
                />
              }
              size="large"
              showSearch
              filterOption={false}
              value={language === "" ? "Select language" : language === "pt" ? "Português" : language === "es" ? "Spanish" : "English"}
              onChange={handleLanguageChange}
              placeholder="Select language"
            >
              <Select.Option value="English">English</Select.Option>
              <Select.Option value="Spanish">Spanish</Select.Option>
              <Select.Option value="Português">Português</Select.Option>
            </Select>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-start">
            <span className="span">Knowledge</span>
            <div className="textarea-data position-relative">
              <textarea value={knowledge} onChange={e => setKnowledge(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center gap-3">
        <ButtonType1
          props={{
            style: { padding: "8px 12px", borderRadius: 8 },
            type: "primary",
            onClick: () => {
              setOpenModal(false);
              resetValue();
            },
          }}
          text={"Close"}
        />
        <ButtonType1
          props={{
            style: { padding: "8px 12px", borderRadius: 8 },
            type: "primary",
            onClick: () => { handleUpdateAssistant() },
            disabled: isLoading || !checkIfFormIsDirty(),
          }}
          text={"Edit"}
        />
      </div>
    </ModalGlobal>
  );
};

export default ModalEditAssistant;
