import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import {
  IconChevronRight,
  IconFileText,
} from "@tabler/icons-react";
import CardAttachment from "./CardAttachment";

const ListAttachmentFiles = ({ type = "static", additionalProps, files }) => {
  const [showList, setShowList] = useState(false);
  const [positionRightButton, setPositionRightButton] = useState(0);
  const sectionList = useRef(null);


  useEffect(() => {
    setPositionRightButton(
      (showList && sectionList?.current?.getBoundingClientRect().width) || 0
    );
  }, [sectionList, showList]);

  return (
    <>
      {type === "fixed" && (
        <button
          className={"float-button button-primary"}
          style={{
            right: positionRightButton,
          }}
          onClick={() => setShowList(!showList)}
        >
          <div className={"content"}>
            {!showList ? (
              <IconFileText stroke={1.5} size={22} />
            ) : (
              <IconChevronRight />
            )}
            {!showList && <span>{files.length}</span>}
          </div>
        </button>
      )}
      <CSSTransition
        in={type === "fixed" ? showList : true}
        classNames="slide-in-right-300"
        timeout={300}
        unmountOnExit
      >
        <section
          ref={sectionList}
          {...additionalProps}
          className={`${additionalProps?.className} section-attachment-files ${
            type === "fixed" ? "fixed-section" : ""
          }`}
        >
          <h6>Upload a file</h6>
          <ul className={`list-attachment-files`}>
            {files.map(file => (
              <CardAttachment attachment={file} key={file._id} />
            ))}
          </ul>
        </section>
      </CSSTransition>
    </>
  );
};

export default ListAttachmentFiles;
