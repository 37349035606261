import ButtonCloseModal from "../Buttons/ButtonCloseModal";

const ModalGlobal = ({
  style,
  children,
  className,
  showBtnClose = true,
  classNameChildren,
  onClick = () => {},
  open,
  id = "main-modal",
}) => {
  return (
    open && (
      <div id={id} className={`modal-custom ${className}`} onClick={() => {onClick()}}>
        <div
          className={`d-flex flex-column align-items-center modal-content-global ${classNameChildren}`}
          onClick={e => e.stopPropagation()}
          style={ style }
        >
          {showBtnClose && (
            <ButtonCloseModal
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            />
          )}
          {children}
        </div>
      </div>
    )
  );
};

export default ModalGlobal;
