import { useState, useEffect } from "react";
import ButtonType1 from "../../../Buttons/ButtonType1";
import { message, Select } from "antd";
import ModalGlobal from "../../../Modals/ModalGlobal";
import { useUser } from "@supabase/auth-helpers-react";
import IconArrowDown from "../../../../assets/images/icons-new-layout/arrow-down.svg";
import { postPayment } from "../../../../api/creditPayments";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../services/notifications";
import { web3Service } from "../../../../services/web3/web3";
import { getAmountOfAIZPerUsdc } from "../../../../api/web3";
import { PAYMENT_METHODS } from "../../../../utils/constants/paymentMethods";
import { BLOCKCHAINS } from "../../../../services/web3/NetworkConnections";
import { QUERY_KEYS, queryClient } from "../../../../libs/react-query";
import {
  IconCreditCard,
  IconLoader2,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import { formatPriceInUSD } from "../../../../utils/helpers";

export function PurchaseCredits({ credits }) {
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [valueAmount, setValueAmount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrcodeImage, setQRCodeImage] = useState(null);
  const [modalPix, setModalPix] = useState(false);
  const [pixCode, setPixCode] = useState("");
  const [aizAmount, setAizAmount] = useState(0);

  const handleChange = value => {
    setSelectedPayment(value);
  };

  const handleDropdownVisibleChange = open => {
    setIsOpenSelect(open);
  };

  const handleInputValue = event => {
    setValueAmount(Number(event.target.value));
  };

  const user = useUser();
  const dataUser = user?.user_metadata;

  const fetchAIZTokenAmount = async () => {
    try {
      if (valueAmount <= 0) {
        return;
      }
      setIsLoading(true);
      const response = await getAmountOfAIZPerUsdc(valueAmount);
      setAizAmount(response.amountOfAIZPerUsdc);
    } catch (error) {
      console.error("Failed to fetch AIZ token amount:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAizSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification("Please enter a value to continue with AIZ");
      return;
    }

    const keyMessage = 'processingTransaction';

    try {
      const BASE_CHAIN_ID = BLOCKCHAINS.BASE.chainId;
      const currentNetwork = await web3Service.getCurrentNetwork();
  
      if (currentNetwork.chainId.toString() !== BASE_CHAIN_ID) {
        alert("Please connect to the correct network.");
        await web3Service.changeNetwork(BASE_CHAIN_ID);
        return;
      }
  
      setIsLoading(true);
  
      const receipt = await web3Service.transferToken("", aizAmount);

      message.loading({ content: 'Processing the transaction from Metamask...', key: keyMessage, duration: 0 });

      const confirmations = await receipt.wait();

      if (confirmations.status !== 1) {
        throw new Error("Transaction failed");
      }

      message.loading({content: "Adding credits to your account...", key: keyMessage, duration: 0});
  
      await postPayment({
        amount: valueAmount,
        method: "token",
        transaction_id: receipt.hash,
      });
  
      await queryClient.invalidateQueries(QUERY_KEYS.USER_CREDITS);
      await queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS);
  
      message.success({ content: 'Credits added successfully!', key: keyMessage, duration: 3.5 });

      setOpenModal(false);
    } catch (error) {  
      if (error?.reason === "ERC20: transfer amount exceeds balance") {
        return message.error({ content: 'Insufficient balance to complete the transaction with AIZ tokens.', key: keyMessage, duration: 5 });
      }
  
      if (error?.reason === "rejected") {
        return;
      }
  
      message.error({ content: error?.message, key: keyMessage, duration: 4 });
  
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handlePixSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification("Please enter a value to continue with Pix");
      return;
    }
    try {
      setIsLoading(true);

      const response = await postPayment({
        amount: valueAmount,
        method: "pix",
      });

      console.log("response", response);

      queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS);

      const qrcodeBase64 = response.pix.qrcode;
      const pixCode = response.pix.txinfoemvqrcps;

      const qrcodeImage = qrcodeBase64?.includes("data:image")
        ? qrcodeBase64
        : `data:image/png;base64,${qrcodeBase64}`;

      setQRCodeImage(qrcodeImage);
      setPixCode(pixCode);
      setOpenModal(false);
      setModalPix(true);
    } catch (error) {
      console.warn("Error during Pix payment:", error);
      showErrorNotification("Failed to generate QR Code.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardSelection = async () => {
    if (valueAmount <= 0) {
      showErrorNotification("Please enter a value to continue with card");
      return;
    }
    try {
      setIsLoading(true);

      const response = await postPayment({
        amount: valueAmount,
        method: "card",
      });

      queryClient.invalidateQueries(QUERY_KEYS.USER_PAYMENTS);

      if (response.payment_url) {
        window.location.href = response.payment_url;
      }
    } catch (error) {
      console.warn("Error during card payment:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClick = () => {
    switch (selectedPayment) {
      case PAYMENT_METHODS.AIZ:
        handleAizSelection();
        break;
      case PAYMENT_METHODS.PIX:
        handlePixSelection();
        break;
      case PAYMENT_METHODS.CARD:
        handleCardSelection();
        break;
      default:
        showErrorNotification("Please select a payment method.");
    }
  };

  const copyCodePix = () => {
    navigator.clipboard.writeText(pixCode);
    showSuccessNotification("Pix code copied to clipboard");
  };

  useEffect(() => {
    if (selectedPayment === PAYMENT_METHODS.AIZ && valueAmount > 0) {
      fetchAIZTokenAmount();
    }
  }, [selectedPayment, valueAmount]);

  return (
    <>
      <div className="plan-settings">
        <div className="w-100 d-flex align-items-center gap-2">
          {credits.isLoading ? (
            <h2>
              <IconLoader2 className="spin" width={16} height={16} />
            </h2>
          ) : (
            <h2>{formatPriceInUSD(Number(credits.amount || 0), 5)}</h2>
          )}
          <span>Available</span>
        </div>
        <div className="d-flex justify-content-end w-100">
          <ButtonType1
            text={"Charge credits"}
            props={{
              disabled: false,
              style: { padding: "10px 16px", borderRadius: 10 },
              onClick: () => {
                setOpenModal(true);
              },
            }}
          />
          {qrcodeImage && (
            <ModalGlobal
              open={modalPix}
              showBtnClose={true}
              onClick={() => {
                setModalPix(false);
              }}
              style={{ width: "100%", maxWidth: 500, minHeight: 500 }}
              classNameChildren={"d-flex flex-column gap-3"}
            >
              <span style={{ textAlign: "center" }}>
                Add credits quickly and easily by scanning the QR CODE below
              </span>
              <img
                src={qrcodeImage}
                alt="QR Code"
                style={{ width: "100%", maxWidth: 300 }}
              />
              <ButtonType1
                text={"Copy Pix code"}
                props={{
                  disabled: false,
                  style: { padding: "10px 16px", borderRadius: 10 },
                  onClick: copyCodePix,
                }}
              />
            </ModalGlobal>
          )}
        </div>
      </div>

      <ModalGlobal
        onClick={() => {
          setOpenModal(false);
        }}
        open={openModal}
        showBtnClose={false}
        className={"gap-4"}
        style={{ minHeight: 600 }}
        classNameChildren={"d-flex flex-column gap-3 justify-content-between"}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex gap-3 align-items-center">
            <button className="d-flex align-items-center">
              <IconCreditCard stroke={1.5} style={{ width: 16, height: 16 }} />
            </button>
            <h4 className="m-0">Credits</h4>
          </div>
          <button className="btn-icon-header">
            <IconX
              stroke={1.5}
              style={{ width: 16, height: 16 }}
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </button>
        </div>

        <div className="w-100 d-flex flex-column gap-4" style={{ height: 350 }}>
          <div className="d-flex flex-column gap-2">
            <span>Select payment method</span>
            <div className="w-100 d-flex flex-column gap-2">
              <Select
                suffixIcon={
                  <img
                    src={IconArrowDown}
                    height={10}
                    width={10}
                    alt="Arrow down"
                    className={isOpenSelect ? "rotate-arrow" : ""}
                  />
                }
                style={{
                  width: 250,
                  border: "1px solid #E3E3E3",
                  borderRadius: 8,
                  cursor: "pointer",
                }}
                size="large"
                showSearch
                filterOption={false}
                value={selectedPayment}
                onChange={handleChange}
                onDropdownVisibleChange={handleDropdownVisibleChange}
              >
                <Select.Option value={PAYMENT_METHODS.PIX}>Pix</Select.Option>
                <Select.Option value={PAYMENT_METHODS.CARD}>Card</Select.Option>
                <Select.Option value={PAYMENT_METHODS.AIZ}>
                  AIZ (token)
                </Select.Option>
              </Select>
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <span>Value credits</span>
            <div
              className="d-flex align-items-center gap-2"
              style={{
                width: 250,
                border: "1px solid #E3E3E3",
                borderRadius: 8,
                cursor: "pointer",
                padding: "0 12px",
              }}
            >
              <span>USD</span>
              <input
                type="number"
                style={{ border: "none", fontSize: 13 }}
                onChange={handleInputValue}
                min={1}
              />
            </div>
          </div>
          {selectedPayment === PAYMENT_METHODS.AIZ && valueAmount > 0 && (
            <div className="d-flex flex-column gap-2">
              <span>
                You will need approximately{" "}
                <span className="fw-semibold">{aizAmount.toFixed(4)} AIZ</span> tokens
                to complete this transaction.
              </span>
            </div>
          )}
          <div>
            <ButtonType1
              text={"Pay"}
              props={{
                disabled: isLoading,
                style: { padding: "10px 16px", borderRadius: 10 },
                onClick: handleClick,
              }}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between gap-2 w-100">
          <div className="d-flex align-items-center gap-2">
            {dataUser?.picture ? (
              <img
                src={dataUser?.picture}
                alt=""
                className="profile-user"
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <IconUser size={20} />
            )}
            <span>{dataUser?.name}</span>
          </div>
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonType1
              text={"Cancel"}
              props={{
                style: { padding: "10px 16px", borderRadius: 10 },
                onClick: () => {
                  setOpenModal(false);
                },
              }}
            />
          </div>
        </div>
      </ModalGlobal>
    </>
  );
}

export default PurchaseCredits;
