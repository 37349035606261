import { useState } from "react";
import { deleteAccount } from "../../api";
import { showErrorNotification } from "../../services/notifications";
import ButtonDefaultAccount from "./ButtonDefaultAccount";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import ModalGlobal from "../Modals/ModalGlobal";
import { IconLoader2 } from "@tabler/icons-react";
import ButtonType1 from "./ButtonType1";

export function DeleteAccountButton({ userId }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);

  async function handleDeleteAccount() {
    try {
      setIsLoading(true);
      if (userId) {
        await deleteAccount(userId);
      }
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setShowConfirmation(false);
      setIsLoading(false);
    }
  }

  function toggleModal() {
    setShowConfirmation(!showConfirmation);
  }

  return (
    <div>
      <ButtonDefaultAccount
        textButton={"Delete my account"}
        onClick={toggleModal}
        border={true}
        width={
          state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG
            ? "220px"
            : "180px"
        }
      />

      <ModalGlobal
        showBtnClose={true}
        onClick={toggleModal}
        open={showConfirmation}
        classNameChildren={"d-flex flex-column gap-3"}
      >
        <h4 className="text-center text-danger">Deletion Confirmation</h4>
        <p
          className="font-size-18"
          dangerouslySetInnerHTML={{
            __html:
              "Are you sure you want to delete your account? This action " +
              '<span class="text-danger fw-bold">CANNOT</span> be undone.',
          }}
        />
        <div className="d-flex gap-3">
          <ButtonType1
            onClick={toggleModal}
            variation="thirty"
            props={{
              disabled: isLoading,
              style: { borderRadius: 8, padding: "8px 12px" },
              onClick: toggleModal,
            }}
            text={"Cancel"}
          />
          <ButtonType1
            variation="thirty"
            props={{
              disabled: isLoading,
              style: { borderRadius: 8, padding: "8px 12px" },
              onClick: handleDeleteAccount,
            }}
            text={
              isLoading ? (
                <IconLoader2 stroke={2} size={20} />
              ) : (
                "I want to delete"
              )
            }
          />
        </div>
      </ModalGlobal>
    </div>
  );
}
