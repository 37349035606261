/* eslint-disable react-hooks/exhaustive-deps */
import {
  IconFileText,
  IconX,
  IconBrowser,
  IconBrandYoutube,
  IconLoader2,
} from "@tabler/icons-react";
import { useState, useEffect } from "react";
import {
  DocumentRepository,
  makeDocumentId,
} from "../../../../services/indexedDB/document-repository";
import { deleteAttachment } from "../../../../api";
import { queryClient } from "../../../../libs/react-query";
import { showErrorNotification } from "../../../../services/notifications";
import Tooltip from "../../../Add-ons/Tooltip";
import Tag from "../../../Add-ons/Tag";
import COLORS from "../../../../utils/constants/colors";

const CardAttachment = ({ attachment, tagCard }) => {
  const [isLoading, setIsLoading] = useState(false);

  const documentRepository = new DocumentRepository();

  const iconColor = attachment?.processed ? "green" : "orange";

  const iconMap = {
    document: <IconFileText size={20} color={iconColor} stroke={2} />,
    website: <IconBrowser size={20} color={iconColor} stroke={2} />,
    video: <IconBrandYoutube size={20} color={iconColor} stroke={2} />,
  };

  const icon = iconMap[attachment?.type] || (
    <IconFileText size={20} color={iconColor} stroke={2} />
  );

  async function handleDeleteAttachment() {
    try {
      setIsLoading(true);

      const repositoryDocumentId = makeDocumentId(
        attachment?.channel_id,
        attachment?.user_id,
        attachment?._id
      );

      await Promise.all([
        deleteAttachment(attachment?._id),
        documentRepository.deleteDocumentById(repositoryDocumentId),
      ]);

      await queryClient.invalidateQueries([
        "attachments",
        attachment?.channel_id,
      ]);
    } catch (error) {
      showErrorNotification(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function saveCompressedDocumentsInIndexedDB() {
      if (attachment?.processed && attachment?.document) {
        const documentId = makeDocumentId(
          attachment?.channel_id,
          attachment?.user_id,
          attachment?._id
        );

        await documentRepository.saveDocument(documentId, attachment?.document);
      }
    }

    saveCompressedDocumentsInIndexedDB();
  }, [attachment?.processed]);

  return (
    <Tag
      tag={tagCard}
      props={{
        className: "d-flex align-items-center gap-2 card-attachment-file",
        style: { maxWidth: 250 },
      }}
    >
      {icon}
      <Tooltip text={attachment.filename}>
        <div className="d-flex flex-column ms-1">
          <span className="one-line small fw-semibold">
            {attachment.filename}
          </span>
          <span
            className="one-line"
            style={{
              fontSize: 12,
            }}
          >
            {!attachment.processed ? attachment.status : "Processed"}
          </span>
        </div>
      </Tooltip>

      <button
        onClick={handleDeleteAttachment}
        className="ms-1 fw-bold delete-button"
        disabled={isLoading}
      >
        {isLoading ? (
          <IconLoader2
            color={COLORS.gray900}
            size={12}
            stroke={3}
            className="spin"
          />
        ) : (
          <IconX color={COLORS.gray900} size={12} stroke={3} />
        )}
      </button>
    </Tag>
  );
};

export default CardAttachment;
