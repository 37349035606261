export const ButtonDeleteAllChatsI18n = {
  "pt-BR": {
    button: {
      label: "Novo chat",
      "delete-all-chats": "Deletar todos os chats",
    },
    notifications: {
      "success-delete-all-chats": "Chats deletados com sucesso!",
    },
    confirm: {
      "delete-all-chats": "Você tem certeza que deseja deletar todos os chats?",
    },
  },
  "en-US": {
    button: {
      label: "New chat",
      "delete-all-chats": "Delete all chats",
    },
    notifications: {
      "success-delete-all-chats": "Chats deleted successfully!",
    },
    confirm: {
      "delete-all-chats": "Are you sure you want to delete all chats?",
    },
  },
  "es-ES": {
    button: {
      label: "Nuevo chat",
      "delete-all-chats": "Eliminar todos los chats",
    },
    notifications: {
      "success-delete-all-chats": "¡Chats eliminados con éxito!",
    },
    confirm: {
      "delete-all-chats": "¿Está seguro de que desea eliminar todos los chats?",
    },
  },
  "fil-PH": {
    button: {
      label: "Bagong chat",
      "delete-all-chats": "Burahin ang lahat ng chat",
    },
    notifications: {
      "success-delete-all-chats": "Matagumpay na binura ang lahat ng chat!",
    },
    confirm: {
      "delete-all-chats":
        "Sigurado ka bang nais mong burahin ang lahat ng chat?",
    },
  },
};
