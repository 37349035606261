import Cookies from "js-cookie";

const _defaultAttributes = {
  path: "/",
  domain: process.env.NODE_ENV === "development" ? "localhost" : ".aizzy.ai",
  expires: 1,
  secure: true,
  sameSite: "Lax",
};

function Cookie() {
  function set(key, value, options?) {
    try {
      Cookies.set(key, value, { ..._defaultAttributes, ...options });
    } catch {}
  }

  function get(key) {
    try {
      return Cookies.get(key);
    } catch {
      return null;
    }
  }

  function remove(key) {
    try {
      Cookies.remove(key, _defaultAttributes);
    } catch {}
  }

  return {
    set,
    get,
    remove,
  };
}

export default Cookie();
