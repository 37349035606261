export const MarkdownRendererI18n = {
  "pt-BR": {
    button: {
      "copy-code": "Copiar",
      copied: "Copiado",
    },
    notifications: {
      "copy-code": "Código copiado com sucesso!",
    },
  },
  "en-US": {
    button: {
      "copy-code": "Copy",
      copied: "Copied",
    },
    notifications: {
      "copy-code": "Code copied successfully!",
    },
  },
  "es-ES": {
    button: {
      "copy-code": "Copiar",
      copied: "Copiado",
    },
    notifications: {
      "copy-code": "Código copiado exitosamente",
    },
  },
  "fil-PH": {
    button: {
      "copy-code": "Kopyahin",
      copied: "Nakopya",
    },
    notifications: {
      "copy-code": "Matagumpay na nakopya ang kodigo!",
    },
  },
};
