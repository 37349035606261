import classnames from "classnames";
import MarkdownRenderer from "./MarkdownRenderer";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../services/notifications";
import useTranslation from "../../../../hooks/useTranslation";
import { MessagesdI18n } from "./ConversationUser.i18n";

import { deleteAssistantMessage } from "../../../../api";
import { useRedux } from "../../../../hooks";
import { ButtonCopyMessage } from "../../../Buttons/ButtonCopyMessage";
import { memo, useEffect, useState } from "react";
import { ChatData } from "../../../../@DTO/ChatData";
import { useChatStore } from "../../../../state/chat";
import {
  ChatRepository,
  makeChatId,
} from "../../../../services/indexedDB/chat-repository";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import SkeletonMessage from "./SkeletonMessage";

interface MessageProps {
  message: ChatData;
}

const Message = ({ message }: MessageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);

  const { removeMessage, isReceivingMessage } = useChatStore();

  const { useAppSelector } = useRedux();

  const configsMainChat = useGetConfigsMainChat();
  // @ts-ignore
  const selectedChat = configsMainChat?.chatData;
  const assistant = configsMainChat?.assistant;

  const assistantId = configsMainChat?.assistant?._id;

  const chatRepository = new ChatRepository();

  const { t } = useTranslation(MessagesdI18n);

  // @ts-ignore
  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.MD;

  const blockDeleteMessage =
    assistant?.userId && assistant?.userPrice > 0 && !assistant?.hired;

  async function handleDeleteMessage(messageId: string) {
    if (isReceivingMessage) return;
    const confirmed = window.confirm(t("confirm"));
    if (confirmed) {
      try {
        setIsLoading(true);

        if (selectedChat?.isDocument || selectedChat?.isYoutube) {
          await deleteAssistantMessage(messageId);
        }

        if (!selectedChat?.isDocument) {
          await chatRepository.deleteMessageById(
            makeChatId(selectedChat?._id, selectedChat?.userId),
            messageId
          );
        }

        removeMessage(messageId);

        showSuccessNotification(t("notifications.successDeleteMessage"));
      } catch (e: any) {
        showErrorNotification(e);
      } finally {
        setIsLoading(false);
      }
    }
  }

  if (message?.chat_response?.content === "" && message.channelId) {
    return null;
  }

  return (
    <div
      className={classnames("chat-list mb-4")}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        className="conversation-list rounded-2"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {message?.chat_request && (
          <div
            className="p-3 justify-content-end"
            style={{ display: "flex", flexDirection: "row", gap: "2rem" }}
          >
            <div
              className="d-flex align-items-center justify-content-end request-message"
              style={{
                background: "#F2F4F7",
                borderRadius: 16,
                padding: 16,
              }}
            >
              <MarkdownRenderer
                className="mb-0 markdown"
                content={message?.chat_request}
              />
            </div>
          </div>
        )}

        <section
          className="d-flex mt-1 mt-md-3 row pe-3"
          style={{
            gap: isMobile ? 12 : 32,
            borderBottomLeftRadius: 8,
            paddingLeft: isMobile ? 30 : "1rem",
            borderBottomRightRadius: 8,
          }}
        >
          {/* {isMobile && (
            <div className="d-flex gap-3 font-size-16">
              <ButtonCopyMessage message={message?.chat_response?.content} />
              {!blockDeleteMessage && (
                <button
                  style={{
                    backgroundColor: "transparent",
                    borderStyle: "none",
                    outlineStyle: "none",
                    color: "inherit",
                    width: 16,
                    height: 16,
                    marginLeft: "auto",
                  }}
                  onClick={() => handleDeleteMessage(message._id)}
                  disabled={isLoading || isReceivingMessage}
                >
                  {isLoading ? (
                    <i className="bx bx-loader bx-spin" />
                  ) : (
                    <i className="bx bx-message-square-x" />
                  )}
                </button>
              )}
            </div>
          )} */}
          <div className="col">
            {!message?.chat_response?.content && <SkeletonMessage />}

            {message?.chat_response?.content !== "" && (
              <>
                <div className="d-flex flex-column ">
                  <div className="d-flex gap-3">
                    <img
                      src={
                        // @ts-ignore
                        message?.profileImage || assistant?.profileImage
                      }
                      alt=""
                      width={32}
                      height={32}
                      style={{ borderRadius: "50%" }}
                    />
                    <div
                      style={{
                        background: "#F2F4F7",
                        borderRadius: 16,
                        padding: 16,
                      }}
                    >
                      <MarkdownRenderer
                        className="mb-0 markdown"
                        content={message?.chat_response?.content as string}
                      />
                    </div>
                  </div>
                  {!isMobile && (
                    <div
                      className="d-flex justify-content-start my-3"
                      style={{ marginLeft: 52 }}
                    >
                      {message?.chat_response?.content && (
                        <div className="d-flex justify-content-start gap-2">
                          <ButtonCopyMessage
                            message={message?.chat_response?.content}
                          />
                          {!blockDeleteMessage && (
                            <button
                              style={{
                                backgroundColor: "transparent",
                                borderStyle: "none",
                                outlineStyle: "none",
                                color: "inherit",
                                width: 32,
                                height: 32,
                                marginLeft: "auto",
                                border: "1px solid #D0D5DD",
                                borderRadius: 8,
                              }}
                              onClick={() => handleDeleteMessage(message._id)}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <i className="bx bx-loader bx-spin" />
                              ) : (
                                <i className="bx bx-message-square-x" />
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default memo(Message);
