import { APIClient } from "./apiCore";

const api = new APIClient();
const API_URL = process.env.REACT_APP_API_URL;
const WEB3_PREFIX = "/api/web3";

const buildWeb3Url = endpoint => `${API_URL}${WEB3_PREFIX}${endpoint}`;

const getAIZPriceInUSDC = () => {
  return api.get(buildWeb3Url("/aiz-price-in-usdc"));
};

const getAmountOfAIZPerUsdc = (priceInUsdc: number) => {
  return api.get(
    buildWeb3Url(`/amount-of-aiz-per-usdc?priceInUsdc=${priceInUsdc}`)
  );
};

const getAmountOfUsdcPerAIZ = (amountOfAIZ: number) => {
  return api.get(
    buildWeb3Url(`/amount-of-usdc-per-aiz?amountOfAIZ=${amountOfAIZ}`)
  );
};

export { getAIZPriceInUSDC, getAmountOfAIZPerUsdc, getAmountOfUsdcPerAIZ };
