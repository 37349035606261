import i18next from "i18next";
import { UserDataDTO } from "../@DTO/userData";
import { getProfileDetails } from "../api";

export function getUser() {
  const data = localStorage.getItem("user");

  if (data) {
    const userData: UserDataDTO = JSON.parse(data);
    return userData;
  }
}

export function checkIfWeb3User() {
  const data = localStorage.getItem("user");

  if (data) {
    const userData: UserDataDTO = JSON.parse(data);

    const isWeb3User = userData.email.includes("@erc20");

    return isWeb3User;
  }

  return false;
}

export async function getAndUpdateUser() {
  const user = await getProfileDetails();
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export function getCurrentUserLanguage() {
  if (i18next?.language.includes("-")) {
    const language = i18next?.language ? i18next.language.split("-")[0] : "en";

    return language;
  }

  return i18next?.language;
}

export function getCurrentUserLanguageWithCountry() {
  const language = i18next?.language;

  return language;
}

export function getCurrentUserPlan() {
  const userDataLS = localStorage.getItem("user");

  if (userDataLS) {
    const userData = JSON.parse(userDataLS);

    const subscription = userData?.app_metadata?.subscription?.product_name;

    return subscription;
  }
}
