import React, { useState, useEffect } from "react";
import { DropdownItem } from "reactstrap";
import { useQuery } from "react-query";
import { web3Service } from "../../services/web3/web3";
import { getAIZPriceInUSDC } from "../../api/web3";
import { formatPriceAiz, formatPriceInUSD } from "../../utils/helpers";
import COLORS from "../../utils/constants/colors";

const DetailsUserBalance = () => {
  const [balance, setBalance] = useState("0");
  const [totalValueInUSD, setTotalValueInUSD] = useState("");

  useEffect(() => {
    async function loadBalance() {
      try {
        const currentAddress = await web3Service.getCurrentAddress();
        const balance = await web3Service.getTokenBalance(currentAddress);
        setBalance(balance);
      } catch (error) {
        console.error("Erro ao carregar o balance:", error);
      }
    }
    loadBalance();
  }, []);

  const { data: aizPriceInUsdc } = useQuery(
    "aizPriceInUsdc",
    getAIZPriceInUSDC,
    {
      refetchInterval: 30000,
    }
  );

  useEffect(() => {
    if (aizPriceInUsdc) {
      calculateTotalValueInUSD(balance, aizPriceInUsdc.aizPriceInUsdc);
    }
  }, [balance, aizPriceInUsdc]);

  const calculateTotalValueInUSD = (balance, aizPriceInUsdc) => {
    const totalValueInUSD = balance * aizPriceInUsdc;
    setTotalValueInUSD(totalValueInUSD);
  };

  return (
    <DropdownItem className="d-flex align-items-center justify-content-between">
      <div
        className="d-flex flex-column"
        style={{
          gap: 12,
        }}
      >
        <strong className="m-0 p-0">Balance</strong>
        <div className="d-flex align-items-end">
          <h3 className="my-0" style={{ marginRight: 12 }}>
            {formatPriceAiz(Number(balance))} AIZ
          </h3>
          <span>2.54%</span>
        </div>
        <span
          className="small"
          style={{
            color: COLORS.gray500,
          }}
        >
          {formatPriceInUSD(Number(totalValueInUSD))}
        </span>
      </div>
      <i className={"bx bx-chevron-right"} />
    </DropdownItem>
  );
};

export default DetailsUserBalance;
