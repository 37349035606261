export const UPDATE_ASSISTANT_DATA = 'UPDATE_ASSISTANT_DATA';
export const RESET_ASSISTANT_DATA = 'RESET_ASSISTANT_DATA';

export const updateAssistantData = (data) => {
  return {
    type: UPDATE_ASSISTANT_DATA,
    payload: data,
  };
};

export const resetAssistantData = () => {
  return {
    type: RESET_ASSISTANT_DATA,
  };
};
