import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeTypeChat from "./ChangeTypeChat";
import { Switch } from "antd";
import ChangeAssistant from "./ChangeAssistant";
import ChangeMachine from "./ChangeMachine";
import { IconChevronDown, IconChevronRight, IconX } from "@tabler/icons-react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
  useGetIsLogged,
} from "../../../../hooks/smallHooks";
import useSendMessage from "../../../../hooks/useSendMessage";
import {
  setConfigsMainChat,
  setOpenLogin,
} from "../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import AttachButton from "./ButtonActions/AttachButton";
import AudioRecorderButton from "./ButtonActions/AudioRecorderButton";
import SendButton from "./ButtonActions/SendButton";
import ModalGlobal from "../../../Modals/ModalGlobal";
import AttachDocumentMenu from "../../../Menus/AttachDocumentMenu";
import Tooltip from "../../../Add-ons/Tooltip";
import COLORS from "../../../../utils/constants/colors";

const InputMainChat = () => {
  const dispatch = useDispatch();

  const [searchChat, setSearchChat] = useState("");
  const [question, setQuestion] = useState("");
  const [searchAssistant, setSearchAssistant] = useState("");

  const [isProcessingAudio, setIsProcessingAudio] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [showModalAttachFile, setShowModalAttachFile] = useState(false);

  const textareaRef = useRef(null);

  const isLogged = useGetIsLogged();
  const state = useSelector(state => state);
  const configsMainChat = useGetConfigsMainChat();

  const { handleCreateChannel: createChannel } = useCreateNewChannel();
  const {
    sendMessage,
    isReceivingMessage,
    disableCancelMessage,
    cancelMessage,
  } = useSendMessage();

  const handleSubmitAudio = async transcription => {
    if (isReceivingMessage) {
      cancelMessage();
    } else if (configsMainChat?.chatData) {
      sendMessage(transcription, true);
    } else {
      setQuestion(transcription);
      await createChannel();
      setTimeout(() => {
        const sendButton = document.querySelector("#btn-send-message");
        if (sendButton) {
          sendButton.click();
        }
      }, 300);
    }
  };

  const handleSendButtonClick = async e => {
    if (!isLogged) {
      dispatch(setOpenLogin(true));
      return;
    }
    e.preventDefault();
    if (isReceivingMessage) {
      cancelMessage();
    } else if (configsMainChat?.chatData) {
      sendMessage(question);
      setQuestion("");
    } else {
      await createChannel();
      setTimeout(() => {
        const sendButton = document.querySelector("#btn-send-message");
        if (sendButton) {
          sendButton.click();
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  }, [question]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        400
      )}px`;
    }
  };

  const handleChange = e => {
    setQuestion(e.target.value);
    adjustHeight();
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendButtonClick(e);
    }
  };

  const isMobile = window.matchMedia("(max-width: 768.92px)").matches;

  const toggleModal = () => {
    setIsOpenModal(true);
  };

  const textTooltip =
    "When activated, this feature allows the IA to conduct Internet searches to provide precise and up-to-date answers to your query.";

  return (
    <>
      {!isMobile && (
        <form action="" className="d-flex flex-column" style={{ gap: 12 }}>
          <textarea
            ref={textareaRef}
            name="input-main-chat"
            id="input-main-chat"
            placeholder="Digite aqui..."
            autocomplete="off"
            value={question}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <div className="group-buttons">
            <div className="selectors">
              <ChangeAssistant
                background={"#fff"}
                setSearchAssistant={setSearchAssistant}
                state={state}
                dispatch={dispatch}
                searchAssistant={searchAssistant}
              />
              <ChangeMachine
                background={"#fff"}
                setSearchChat={setSearchChat}
                state={state}
                dispatch={dispatch}
                searchChat={searchChat}
              />

              <ChangeTypeChat />

              <div className="d-flex align-items-center gap-2">
                <Tooltip text={textTooltip} key={""} children={""}>
                  <span className="small">Search on Web</span>
                </Tooltip>
                <Switch
                  size="small"
                  style={{
                    backgroundColor: configsMainChat?.enableWebSearch
                      ? "#000"
                      : "",
                  }}
                  onChange={checked => {
                    dispatch(
                      setConfigsMainChat(
                        KEYS_MAIN_CHAT.enableWebSearch,
                        checked
                      )
                    );
                  }}
                  defaultChecked={configsMainChat?.enableWebSearch}
                />
              </div>
            </div>
            <div className="action-buttons">
              <AttachButton
                onClick={() => {
                  isLogged
                    ? setShowModalAttachFile(true)
                    : dispatch(setOpenLogin(true));
                }}
              />
              <AudioRecorderButton
                onStopRecorder={handleSubmitAudio}
                onAudioProcessing={setIsProcessingAudio}
              />
              {!isProcessingAudio && (
                <SendButton
                  isReceivingMessage={isReceivingMessage}
                  disableCancelMessage={disableCancelMessage}
                  onClick={handleSendButtonClick}
                />
              )}
            </div>
            <ModalGlobal
              id="modal-attach-file"
              open={showModalAttachFile}
              onClick={() => setShowModalAttachFile(false)}
            >
              <AttachDocumentMenu
                isOpen={true}
                onToggle={() => setShowModalAttachFile(false)}
                createDocumentEmbeddingLoading={false}
              />
            </ModalGlobal>
          </div>
        </form>
      )}
      <>
        {isMobile && (
          <form action="" className="d-flex flex-column" style={{ gap: 12 }}>
            <div className="d-flex input-main-chat-mobile">
              <textarea
                ref={textareaRef}
                name="input-main-chat"
                id="input-main-chat"
                placeholder="Digite aqui..."
                autocomplete="off"
                value={question}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <div className="action-buttons">
                {!isProcessingAudio && (
                  <SendButton
                    isReceivingMessage={isReceivingMessage}
                    disableCancelMessage={disableCancelMessage}
                    onClick={handleSendButtonClick}
                  />
                )}
              </div>
            </div>
            <div className="group-buttons">
              <div className="selectors w-100 justify-content-between">
                <div className="media-send-actions-mobile d-flex align-items-center gap-2">
                  <ChangeTypeChat />
                  <div className="d-flex align-items-center gap-2">
                    <AttachButton
                      onClick={() => setShowModalAttachFile(true)}
                    />
                    <AudioRecorderButton
                      onStopRecorder={handleSubmitAudio}
                      onAudioProcessing={setIsProcessingAudio}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className="change-assistant-wrapper d-flex align-items-center gap-2"
                    onClick={toggleModal}
                    style={{ background: "#fff", height: 40, borderRadius: 8, width: 100 }}
                  >
                    <span>Settings</span>
                    <div className="wrapper-icon">
                      <IconChevronRight size={14} />
                    </div>
                  </div>
                  <ModalGlobal
                    open={isOpenModal}
                    showBtnClose={false}
                    classNameChildren={
                      "p-4 animate__animated animate__fadeInDown animate__faster h-100"
                    }
                  >
                    <div className="w-100 h-100 d-flex flex-column gap-3">
                      <div className="d-flex justify-content-end mb-2">
                        <button
                          style={{
                            background: COLORS.gray100,
                            width: 30,
                            height: 30,
                            borderRadius: 8,
                          }}
                          onClick={() => setIsOpenModal(false)}
                        >
                          <IconX
                            stroke={1.5}
                            style={{ width: 16, height: 16 }}
                          />
                        </button>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <span>Assistants</span>
                        <div>
                          <ChangeAssistant
                            background={COLORS.gray100}
                            setSearchAssistant={setSearchAssistant}
                            state={state}
                            dispatch={dispatch}
                            searchAssistant={searchAssistant}
                          />
                        </div>
                      </div>
                      <div className="divider"></div>
                      <div className="d-flex flex-column gap-2">
                        <span>Language model</span>
                        <ChangeMachine
                          background={COLORS.gray100}
                          setSearchChat={setSearchChat}
                          state={state}
                          dispatch={dispatch}
                          searchChat={searchChat}
                        />
                      </div>
                    </div>
                  </ModalGlobal>
                </div>
              </div>

              <ModalGlobal
                id="modal-attach-file"
                open={showModalAttachFile}
                onClick={() => setShowModalAttachFile(false)}
              >
                <AttachDocumentMenu
                  isOpen={true}
                  onToggle={() => setShowModalAttachFile(false)}
                  createDocumentEmbeddingLoading={false}
                />
              </ModalGlobal>
            </div>
          </form>
        )}
      </>
    </>
  );
};

export default InputMainChat;
