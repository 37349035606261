import { ExternalToast, toast } from "sonner";

const showSuccessNotification = (message: string, options?: ExternalToast) => {
  toast.success(message, options);
};

const showWarningNotification = (message: string, options?: ExternalToast) => {
  toast.warning(message, options);
};

const showErrorNotification = (error: string, options?: ExternalToast) => {
  toast.error(error, options);
};

const showOnceADayAlert = (message: string, options?: ExternalToast) => {
  const lastAlertDateString = localStorage.getItem("lastAlertDate");

  if (lastAlertDateString) {
    const currentDate = new Date();
    const lastAlertDate = new Date(lastAlertDateString);

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const timeDifference = currentDate.getTime() - lastAlertDate.getTime();

    if (timeDifference >= oneDayInMilliseconds) {
      toast.warning(message, {
        ...options,
      });
      localStorage.setItem("lastAlertDate", currentDate.toISOString());
    }
  } else {
    toast.warning(message, {
      ...options,
    });
    localStorage.setItem("lastAlertDate", new Date().toISOString());
  }
};

export {
  showSuccessNotification,
  showWarningNotification,
  showErrorNotification,
  showOnceADayAlert,
};