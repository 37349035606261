const initialState = {
    assistantData: {
      name: '',
      description: '',
      role: '',
      category: '',
      prompt: '',
      defaultGreetings: '',
      language: '',
      knowledge: '',
      avatar: null,
    },
  };
  
  const assistantReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_ASSISTANT_DATA':
        return {
         ...state,
          assistantData: {...state.assistantData,...action.payload },
        };
      case 'RESET_ASSISTANT_DATA':
        return {...state, assistantData: initialState.assistantData };
      default:
        return state;
    }
  };
  
  
  export default assistantReducer;
  