import { Select } from "antd";
import IconArrowDown from "../../../assets/images/icons-new-layout/arrow-down.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import ButtonDefaultAccount from "../../Buttons/ButtonDefaultAccount";
import { IconEdit, IconUser } from "@tabler/icons-react";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import ButtonType1 from "../../Buttons/ButtonType1";
import { ButtonDeleteAllChats } from "../../Buttons/ButtonDeleteAllChats";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../services/notifications";
import i18next from "i18next";
import { DeleteAccountButton } from "../../Buttons/ButtonDeleteAccount";
import { setLanguageAction } from "../../../redux/general/action";
import configsGeneralReducer from "../../../redux/general/reducer";
import { useLogout } from "../../../hooks/smallHooks";

const GeneralSettings = () => {
  const state = useSelector(state => state);
const logout = useLogout();

  const user = useUser();
  const dataUser = user?.user_metadata;

  const codeLanguage = {
    Português: "pt-BR",
    English: "en-US",
    Español: "es-ES",
  };
  const languageValue = Object.keys(codeLanguage).find(
    key => codeLanguage[key] === dataUser?.language
  );

  const [language, setLanguage] = useState(languageValue);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [defaultlValue, setDefaultValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    handleUpdateProfile();
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
    setName(event.target.value);
  };

  const widthButtonEdit =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.XL
      ? 400
      : state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG
        ? 250
        : 180;

  const widthLabelSpan =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG ? 200 : 100;

  useEffect(() => {
    if (dataUser) {
      setInputValue(dataUser?.name);
      setDefaultValue(dataUser?.name);
    }
  }, [dataUser]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target.closest(".edit-name") &&
        !event.target.classList.contains("edit-icon")
      ) {
        setIsEditing(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const supabaseClient = useSupabaseClient();

  const handleUpdateProfile = async () => {
    try {
      if (!name) {
        return;
      }
      setLoading(true);
      const { error } = await supabaseClient.auth.updateUser({
        data: { name },
      });
      setIsEditing(false);

      await supabaseClient.auth.refreshSession();

      showSuccessNotification("Profile updated successfully");
      if (error) {
        showErrorNotification(error.message);
      } else {
      }
    } catch (error) {
      showErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  async function handleUpdateLanguage(language) {
    setLanguage(language);

    try {

      localStorage.setItem("configsGeneralReducer", JSON.stringify({
        language: codeLanguage[language],
      }))
      await supabaseClient.auth.updateUser({
        data: {
          language: codeLanguage[language],
        },
      });

      await supabaseClient.auth.refreshSession();

      i18next.changeLanguage(codeLanguage[language]);
      window.location.reload();
    } catch (error) {
      console.error(error);
      showErrorNotification("Error updating language");
    } finally {
    }
  }

  useEffect(() => {
  const storedConfigs = localStorage.getItem('configsGeneralReducer');
  if (storedConfigs) {
    const configs = JSON.parse(storedConfigs);
    const language = Object.keys(codeLanguage).find(key => codeLanguage[key] === configs.language);
    if (language) {
      setLanguage(language);
    }
  }
}, []);

  return (
    <>
      <div className="d-flex flex-column" style={{ gap: 40 }}>
        <div className="d-flex flex-column gap-3">
          <h4>Personal Info</h4>
          <div className="d-flex flex-column gap-3">
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{ width: widthButtonEdit }}
            >
              <span style={{ width: widthLabelSpan }}>Full Name</span>
              <div
                className="d-flex align-items-center gap-2 edit-name"
                style={{
                  width: widthButtonEdit,
                  backgroundColor: isEditing ? "transparent" : "#F2F4F7",
                  height: 40,
                  padding: isEditing ? 0 : "0 16px",
                  borderRadius: 8,
                }}
              >
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  style={{
                    border: isEditing ? "1px solid #98A2B3" : "none",
                    padding: isEditing ? "0 16px" : "0",
                    borderRadius: isEditing ? "8px" : "0",
                  }}
                />
                {isEditing ? (
                  <ButtonType1
                    variation="primary"
                    text={"Save"}
                    isLoading={loading}
                    props={{
                      onClick: handleSave,
                      style: {
                        padding: "10px 16px",
                        borderRadius: 10,
                        marginLeft: 8,
                      },
                      disabled: inputValue === defaultlValue,
                    }}
                  ></ButtonType1>
                ) : (
                  <IconEdit
                    onClick={handleEdit}
                    className="edit-icon"
                    style={{ cursor: "pointer", width: 16, height: 16 }}
                  />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span style={{ width: widthLabelSpan }}>Email</span>
              <ButtonDefaultAccount
                textButton={dataUser?.email}
                textAlign={"start"}
                width={widthButtonEdit}
              ></ButtonDefaultAccount>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span style={{ width: widthLabelSpan }}>User</span>
              <div style={{ width: widthButtonEdit }}>
                <ButtonDefaultAccount
                  input={true}
                  width={"180px"}
                  textButton={dataUser?.name}
                  textAlign={"start"}
                >
                  {dataUser?.profile ? (
                    <img src={dataUser?.picture} alt="" />
                  ) : (
                    <IconUser
                      style={{ width: 16, height: 16, marginRight: 8 }}
                    />
                  )}
                </ButtonDefaultAccount>
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="d-flex flex-column gap-3">
          <h4>Preferences</h4>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ width: widthLabelSpan }}>Language</span>
            <Select
              className="select-antd-create-assistant"
              suffixIcon={
                <img
                  src={IconArrowDown}
                  height={10}
                  width={10}
                  alt="Arrow down"
                />
              }
              size="large"
              showSearch
              filterOption={false}
              value={language === undefined ? state?.configsGeneralReducer?.currentLanguage : language}
              onChange={value => handleUpdateLanguage(value)}
            >
              <Select.Option value="English">English</Select.Option>
              <Select.Option value="Español">Español</Select.Option>
              <Select.Option value="Português">Português</Select.Option>
            </Select>
          </div>
        </div>
        <div className="divider"></div>
        <div className="d-flex flex-column gap-3">
          <h4>System</h4>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ width: widthLabelSpan }}>Active sessions</span>
            <ButtonDefaultAccount
              border={true}
              width={
                state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG
                  ? "220px"
                  : "180px"
              }
              textButton={"Logout session"}
              onClick={logout}
            ></ButtonDefaultAccount>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ width: widthLabelSpan }}>Chat history</span>
            <ButtonDeleteAllChats buttonNewLayout />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span style={{ width: widthLabelSpan }}>Delete account</span>
            {/* <ButtonDefaultAccount
              border={true}
              width={"136px"}
              textButton={"Learn more"}
              icon={true}
              iconType={<IconSquareArrowRight />}
            ></ButtonDefaultAccount> */}
            <DeleteAccountButton userId={user?.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralSettings;
