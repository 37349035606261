const ButtonCloseModal = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={className}
      style={{ position: "absolute", top: "15px", right: "15px" }}
    >
      <i className="bx bx-x fs-3" />
    </button>
  );
};

export default ButtonCloseModal;
