import useChatWebSocket from "./useChatWebSocket";
import { useChatStore } from "../state/chat";
import { showWarningNotification } from "../services/notifications";
import { nanoid } from "nanoid";
import { getBearerToken } from "../services/localStorageHelpers";
import {
  ChatRepository,
  makeChatId,
} from "../services/indexedDB/chat-repository";
import { useGetConfigsMainChat } from "./smallHooks";
import { DocumentRepository } from "../services/indexedDB/document-repository";
import { createTextEmbeddings } from "../api/embeddings";
import { getCurrentUserLanguage } from "../services/userHelper";

const useSendMessage = () => {
  const configsMainChat = useGetConfigsMainChat();

  const { setNewMessage, isReceivingMessage, disableCancelMessage } =
    useChatStore();

  const { sendWebsocketMessage, cancelMessage } = useChatWebSocket();

  const chatRepository = new ChatRepository();
  const documentRepository = new DocumentRepository();

  const userLanguage = getCurrentUserLanguage();

  const sendMessage = async (question, isAudioMessage = false) => {
    const messageId = nanoid();
    const messageBody = {
      question: question,
      assistantId: configsMainChat.assistant?._id ?? null,
      channelId: configsMainChat.chatData?._id,
      messageId,
      isAudioMessage,
      enableWebSearch: configsMainChat.enableWebSearch,
      userLanguage,
      profileImage: configsMainChat?.assistant?.profileImage,
      model: configsMainChat.machine,
    };

    const message = {
      body: messageBody,
      jwt: getBearerToken(),
    };

    const messageData = {
      mId: messageId,
      chat_request: question,
      profileImage: configsMainChat?.assistant?.profileImage,
      chat_response: {
        content: "",
      },
    };

    setNewMessage(messageData);

    const typeChatPrefix = configsMainChat.typeChat?.prefix || "";

    if (typeChatPrefix === "/attachment") {
      const { documents } = await createTextEmbeddings({
        channelId: configsMainChat.chatData._id,
        text: question,
      });

      const postgresIds = documents.map(document => document.id);

      const compressedContent =
        await documentRepository.getCompressedDocumentsByPostgresIds(
          postgresIds
        );

      message.body.compressedDocuments = compressedContent ?? null;
    }

    message.body.typeChat = {
      prefix: typeChatPrefix,
    };

    try {
      const messages = await chatRepository.getMessagesByChatId(
        makeChatId(
          configsMainChat?.chatData._id,
          configsMainChat.chatData?.userId
        )
      );
      const lastMessages = messages?.reverse().slice(0, 7).reverse();
      const responseMessages =
        lastMessages?.map(msg => msg.chat_response?.content) || [];

      const data = {
        route: "createChatMessage",
        body: {
          ...message.body,
          lastMessages: responseMessages,
        },
        jwt: message.jwt,
      };

      const finalMessage = JSON.stringify({
        route: "createChatMessage",
        jwt: data.jwt,
        body: data.body,
      });

      sendWebsocketMessage(finalMessage);
    } catch (error) {
      showWarningNotification(error);
    }
  };

  return {
    isReceivingMessage,
    disableCancelMessage,
    cancelMessage,
    sendMessage,
  };
};

export default useSendMessage;
