import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import ButtonType1 from "../../Buttons/ButtonType1";
import { getUser } from "../../../services/userHelper";
import { showSuccessNotification } from "../../../services/notifications";

const AffiliationSettings = () => {
  const user = getUser();

  return (
    <div className="d-flex flex-column affiliation-settings">
      <div className="d-flex flex-column gap-3">
        <h4>Affiliate program</h4>
        <div
          className="d-flex flex-column"
          style={{
            padding: 20,
            backgroundColor: "#F2F4F7",
            borderRadius: 16,
            gap: 32,
          }}
        >
          <div className="d-flex flex-column gap-3">
            <h4>🎉 Introducing our affiliate program</h4>
            <p>
              As an affiliate, you will have the opportunity to share our AI
              with your network. And the best part? You will receive a 10%
              commission for every subscription made through your exclusive
              link.
            </p>
            <span>How it works?</span>
            <ul>
              <li>
                <div>1</div>
                <p>Copy your affiliate link</p>
              </li>
              <li>
                <div>2</div>
                <p>
                  Share it in your social media, blog, websites or any channel
                </p>
              </li>
              <li>
                <div>3</div>
                <p>
                  The more subscriptions are made through, higher earning you’ll
                  have!
                </p>
              </li>
            </ul>
          </div>
          <div>
            <ButtonType1
              variation="primary"
              text={"Get my affiliate link"}
              props={{
                style: { padding: "10px 16px", borderRadius: 10 },
                onClick: () =>
                  navigator.clipboard
                    .writeText(
                      `https://aizzy.ai?referral=${user?.user_metadata?.affiliate?.userNanoId}`
                    )
                    .then(() => showSuccessNotification("Link copied")),
              }}
            />
          </div>
          <div>
            <p className="font-size-12">
              By using the affiliate link, you agree to the terms and conditions
              of our affiliate program. For more information, please refer to
              our Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4>Taximeter</h4>
        <div className="d-flex flex-column gap-3" style={{ padding: 20 }}>
          <h4>🎉 Introducing our affiliate program</h4>
          <p>
            As an affiliate, you will have the opportunity to share our AI with
            your network. And the best part? You will receive a 10% commission
            for every subscription made through your exclusive link.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AffiliationSettings;
