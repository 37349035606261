import {
  IconMessage,
  IconCode,
  IconPaperclip,
  IconAnalyze,
} from "@tabler/icons-react";

const KEYS_MAIN_CHAT = {
  machine: "machine",
  chatData: "chatData",
  assistant: "assistant",
  typeChat: "typeChat",
  enableWebSearch: "enableWebSearch",
};

// const prefixDocumentMessage = {
//       en: "Given the context",
//       pt: "Dado o contexto",
//       es: "Dado el contexto"
//     };

const LIST_TYPE_CHAT = [
  {
    title: "message",
    icon: <IconMessage stroke={1.5} />,
    description: "Chat with the AI in a normal conversation",
    prefix: "",
  },
  // {
  //   title: "image",
  //   icon: <IconPhoto stroke={1.5} />,
  //   description: "Generate images based on your input",
  //   prefix: "/image",
  // },
  {
    title: "code",
    icon: <IconCode stroke={1.5} />,
    description: "Get better code suggestions and generation",
    prefix: "/code",
  },
  {
    title: "attachment",
    icon: <IconPaperclip stroke={1.5} />,
    description:
      "Analyze and respond specifically to attached content in chat such as documents, YouTube videos, or websites",
    prefix: "/attachment",
  },
];

const defaultConfigsMainChat = (props = {}) => {
  return {
    [KEYS_MAIN_CHAT.machine]: props.machine,
    [KEYS_MAIN_CHAT.chatData]: props.chatData,
    [KEYS_MAIN_CHAT.assistant]: props.assistant,
    [KEYS_MAIN_CHAT.typeChat]: props.typeChat,
    [KEYS_MAIN_CHAT.enableWebSearch]: props.enableWebSearch,
  };
};

export default defaultConfigsMainChat;
export { KEYS_MAIN_CHAT, LIST_TYPE_CHAT };
