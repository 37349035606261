import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);

  const showTooltip = () => {
    setVisible(true);
    setTimeout(() => {
      setShow(true);
    }, 300);
  };

  const hideTooltip = () => {
    setShow(false);
    setTimeout(() => {
      setVisible(false);
    }, 300);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div className={`tooltip-box ${show ? "show" : ""}`}>
          {text}
          <div className="tooltip-arrow" />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
