import { showWarningNotification } from "../services/notifications";

export const getURL = () => {
  let url = process.env.REACT_APP_PUBLIC_SITE_URL
    ? process.env.REACT_APP_PUBLIC_SITE_URL
    : "http://localhost:3000";

  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get("redirectTo");

  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;

  if (redirectTo) {
    // if (redirectTo.includes("http://localhost:3000")) {
    //   return redirectTo;
    // }
    return validateRedirectTo(redirectTo);
  }
  return url;
};

export function validateRedirectTo(redirectTo: string) {
  const isLocalHost =
    process.env.REACT_APP_PUBLIC_SITE_URL?.toString() ===
    "http://localhost:3000/";

  if (
    (redirectTo.includes("aizzy.ai") && redirectTo.includes("https")) ||
    (isLocalHost && redirectTo.includes("http://localhost:3000"))
  )
    return redirectTo;

  return showWarningNotification("Invalid route");
}

export const postData = async ({ url, data }: { url: string; data?: any }) => {
  const res: Response = await fetch(url, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    console.error("Error in postData", { url, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const makeRequest = async ({
  url,
  data,
  method,
}: {
  url: string;
  data?: any;
  method: string;
}) => {
  const res: Response = await fetch(url, {
    method: method,
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    console.error("Error in postData", { url, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const toDateTime = (secs: number) => {
  var t = new Date("1970-01-01T00:30:00Z"); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export function removeMarkdownImageSyntax(markdown) {
  const regex = /!\[.*?\]\((.*?)\)/g;
  return markdown?.replace(regex, "$1");
}

export function addCommaToNumber(number: number) {
  if (!number) {
    return;
  }
  const numberStr: string = number.toString();
  const formattedNumber: string = numberStr.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
  return formattedNumber;
}

export function getBrowserLanguage() {
  const DEFAULT_LANGUAGE = "en-US";
  const languageConversions = {
    en: "en-US",
    pt: "pt-BR",
    es: "es-ES",
  };
  function convertLanguage(browserLanguage) {
    const [language, country] = browserLanguage?.split("-");
    for (let languageConversion in languageConversions) {
      if (language === languageConversion) {
        return languageConversions[languageConversion];
      }
    }
    return DEFAULT_LANGUAGE;
  }
  return convertLanguage(navigator?.language);
}

export function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent as string;
}

export function formatCurrency(value: number): string {
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  });
}

export async function delayInMs(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const isMobile = window.matchMedia("(max-width: 767px)").matches;

export const isMobileMenuChat = window.matchMedia("(max-width: 992px)").matches;

export const formatPriceInUSD = (price: number, decimalCases?: number) => {
  return `$${price.toFixed(decimalCases || 0)}`;
};
export const formatPriceAiz = price => {
  return `${price.toFixed(4)}`;
};
