import { useQuery } from "react-query";
import { useEffect } from "react";
import CardAttachment from "./CardAttachment";
import { IconLoader2 } from "@tabler/icons-react";
import ListAttachmentFiles from "./ListAttachmentFiles";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import { getChannelJobStatus } from "../../../../api";

export function PreviewAttachmentFiles({ onEmpty }) {
  const configsMainChat = useGetConfigsMainChat();

  const {
    data: attachments,
    isLoading,
    refetch,
  } = useQuery(
    ["attachments", configsMainChat?.chatData?._id],
    async () => getChannelJobStatus(configsMainChat?.chatData?._id),
    {
      enabled: configsMainChat?.chatData?._id !== undefined,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (attachments && attachments.some(attachment => !attachment.processed)) {
      const interval = setInterval(() => {
        refetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [attachments, refetch]);

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  if (!attachments || attachments.length === 0) {
    onEmpty(true);
    return null;
  } else {
    onEmpty(false);
  }

  return (
    <div>
      {isLoading ? (
        <span className="small d-flex align-items-center gap-2">
          Loading attachments... <IconLoader2 className="spin" size={16} />
        </span>
      ) : (
        <>
          <div className="d-flex align-items-center gap-3 animate__animated animate__fadeInDown animated animate__faster">
            {(attachments || []).slice(0, isMobile ? 1 : 3).map(attachment => (
              <CardAttachment attachment={attachment} key={attachment._id} />
            ))}
          </div>
          <div>
            {attachments && attachments.length > (isMobile ? 1 : 3) && (
              <ListAttachmentFiles
                files={attachments}
                type="fixed"
                additionalProps={{
                  style: { width: 250, alignSelf: "start", marginTop: 20 },
                }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
