import { ChatsActionTypes } from "./types";

// common success
export const chatsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ChatsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const chatsApiResponseError = (actionType: string, error: any) => ({
  type: ChatsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getChannelsAndDocuments = () => ({
  type: ChatsActionTypes.GET_CHANNELS_AND_DOCUMENTS,
  // payload: data,
});

export const getDocuments = () => ({
  type: ChatsActionTypes.GET_DOCUMENTS,
});

export const getDirectMessages = () => ({
  type: ChatsActionTypes.GET_DIRECT_MESSAGES,
});

export interface CreateChannelPostData {
  channelName?: string;
  assistantId?: string | number;
  isDocument: boolean;
  isImage?: boolean;
}
export const createChannel = (channelData: CreateChannelPostData) => ({
  type: ChatsActionTypes.CREATE_CHANNEL,
  payload: channelData,
});

export const createTopicChannel = (channelData: CreateChannelPostData) => ({
  type: ChatsActionTypes.CREATE_TOPIC_CHANNEL,
  payload: channelData,
});

export const changeSelectedChat = (selectedChat: any) => ({
  type: ChatsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});

export const getChatUserDetails = (selectedChat: string | number | null) => ({
  type: ChatsActionTypes.GET_CHAT_USER_DETAILS,
  payload: selectedChat,
});

export const getChatUserConversations = (selectedChat: any) => ({
  type: ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
  payload: selectedChat,
});

export const getChannelDocumentsUrls = (selectedChat: any) => ({
  type: ChatsActionTypes.GET_CHANNEL_DOCUMENTS_URLS,
  payload: selectedChat,
});

export const handleChatUserConversations = (conversation: any) => ({
  type: ChatsActionTypes.HANDLE_CHAT_USER_CONVERSATIONS,
  payload: conversation,
});

export const getChatUserConversationsReset = () => ({
  type: ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
});

export const getChatTopicChannels = (topic: any) => ({
  type: ChatsActionTypes.GET_CHAT_TOPIC_CHANNELS,
  payload: topic,
});

export const toggleUserDetailsTab = (value: boolean) => ({
  type: ChatsActionTypes.TOGGLE_USER_DETAILS_TAB,
  payload: value,
});

export const receiveMessage = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE,
  payload: id,
});

export const readMessage = (id: number | string) => ({
  type: ChatsActionTypes.READ_MESSAGE,
  payload: id,
});

export const receiveMessageFromUser = (id: number | string) => ({
  type: ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER,
  payload: id,
});

export const deleteMessage = (
  userId: number | string,
  messageId: number | string
) => ({
  type: ChatsActionTypes.DELETE_MESSAGE,
  payload: { userId, messageId },
});

export const forwardMessage = (data: object) => ({
  type: ChatsActionTypes.FORWARD_MESSAGE,
  payload: data,
});

export const deleteUserMessages = (userId: number | string) => ({
  type: ChatsActionTypes.DELETE_USER_MESSAGES,
  payload: userId,
});

export const getChannelDetails = (id: number | string) => ({
  type: ChatsActionTypes.GET_CHANNEL_DETAILS,
  payload: id,
});

export const readConversation = (id: number | string) => ({
  type: ChatsActionTypes.READ_CONVERSATION,
  payload: id,
});

export const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => ({
  type: ChatsActionTypes.DELETE_IMAGE,
  payload: { userId, messageId, imageId },
});

export const createDocumentEmbedding = payload => ({
  type: ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING,
  payload: payload,
});

export const createDocumentEmbeddingUpload = payload => ({
  type: ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_UPLOAD,
  payload: payload,
});

export const createVideoEmbedding = payload => ({
  type: ChatsActionTypes.CREATE_DOCUMENT_EMBEDDING_VIDEO,
  payload: payload,
});

export const changeAssistant = payload => ({
  type: ChatsActionTypes.CHANGE_ASSISTANT,
  payload,
});

export const changeTopic = payload => ({
  type: ChatsActionTypes.CHANGE_TOPIC,
  payload,
});

export const getChannelJobStatus = payload => ({
  type: ChatsActionTypes.GET_CHANNEL_JOB_STATUS,
  payload,
});

export const getChannelJobStatusReset = (payload = []) => ({
  type: ChatsActionTypes.GET_CHANNEL_JOB_STATUS_RESET,
  payload,
});

export const createYoutubeChannel = payload => ({
  type: ChatsActionTypes.CREATE_YOUTUBE_CHANNEL,
  payload,
});
