import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import GroupLogin from "../../components/Login/GroupLogin";
import settings from "./settings";

const Settings = () => {
  const state = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = useState(0);

  const { openLeftBar, openLogin } = state?.configsGeneralReducer || {};

  const handleSettingClick = (index) => {
    setSelectedTab(index);
    localStorage.setItem("selectedTab", index);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("success") === "true") {
      const planIndex = settings.findIndex((setting) => setting.id === "credits");
      setSelectedTab(planIndex);
      localStorage.setItem("selectedTab", planIndex);
    } else {
      const savedTab = localStorage.getItem("selectedTab");
      if (savedTab !== null) {
        setSelectedTab(parseInt(savedTab, 10));
      }
    }
  }, []);

  return (
    <section className={`page-settings ${!openLeftBar ? "maximize" : ""}`}>
      <MenuTopBar publishAssistant={false} />
      <div className="d-flex flex-column w-100 container-account">
        <h2 style={{ marginBottom: 32 }}>Account Settings</h2>
        <div className="d-flex container-page-settings" style={{ gap: "80px" }}>
          <div
            className="d-flex flex-column gap-2"
            style={{ width: "100%", maxWidth: "220px" }}
          >
            {settings?.map((setting, index) => {
              return (
                <button
                  key={setting.id}
                  className={`btn-setting-tab w-100 d-flex flex-column gap-2 setting-active
                  ${selectedTab === index ? "active" : ""}`}
                  onClick={() => handleSettingClick(index)}
                >
                  <div className="d-flex gap-3 align-items-center">
                    <div className="d-flex align-items-center">
                      {setting.icon}
                    </div>
                    {setting.label}
                  </div>
                </button>
              );
            })}
          </div>
          <div className="w-100" style={{ maxWidth: 632 }}>
            {settings[selectedTab].content}
          </div>
        </div>
      </div>
      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default Settings;
