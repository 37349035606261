import { combineReducers } from "redux";

import Chats from "./chats/reducer";
import configsGeneralReducer from "./general/reducer";
import assistantReducer from "./createAssistants/reducer";

export default combineReducers({
  Chats,
  configsGeneralReducer,
  assistantReducer,
});
