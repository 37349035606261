import Dexie from "dexie";

export const storeNames = {
  users: "users",
  assistants: "assistants",
  chats: "chats",
  documents: "documents",
};

export class BaseService {
  protected db: Dexie;
  protected storeName: string;

  constructor(storeName: string) {
    this.storeName = storeName;
    this.db = new Dexie("aizzy");
    this.db.version(1).stores({
      [storeName]: "++id",
    });
  }

  async add(item) {
    try {
      if (!item) {
        return null;
      }

      if (item.id) {
        const exists = await this.get(item.id);
        if (exists) {
          return null;
        }
      }

      return await this.db.table(this.storeName).add(item);
    } catch (error) {
      console.error(`Error adding item to ${this.storeName}:`, error);
      throw error;
    }
  }

  async get(id) {
    try {
      const result = await this.db.table(this.storeName).get(id);
      return result || null;
    } catch (error) {
      console.error(
        `Error getting item from ${this.storeName} with id ${id}:`,
        error
      );
      throw error;
    }
  }

  async getAll() {
    try {
      return await this.db.table(this.storeName).toArray();
    } catch (error) {
      console.error(`Error fetching all items from ${this.storeName}:`, error);
      throw error;
    }
  }

  async update(id, updates) {
    try {
      return await this.db.table(this.storeName).update(id, updates);
    } catch (error) {
      console.error(
        `Error updating item in ${this.storeName} with id ${id}:`,
        error
      );
      throw error;
    }
  }

  async delete(id) {
    try {
      await this.db.table(this.storeName).delete(id);
    } catch (error) {
      console.error(
        `Error deleting item from ${this.storeName} with id ${id}:`,
        error
      );
      throw error;
    }
  }
}
