import { ComponentProps, memo } from "react";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";

interface ButtonGoFinalChatProps extends ComponentProps<"button"> {}

export const ButtonGoFinalChat = memo(({ ...rest }: ButtonGoFinalChatProps) => {
  const state = useSelector(state => state);
  // @ts-ignore
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.MD;

  return (
    <div
      className="position-absolute mb-2"
      style={{
        bottom: isMobile ? 30 : 50,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 100,
      }}
    >
      <button
        className="btn btn-sm btn-secondary rounded-circle d-flex justify-content-center align-items-center"
        style={{
          width: 34,
          height: 34,
        }}
        {...rest}
      >
        <i className="bx bx-down-arrow-alt font-size-24" />
      </button>
    </div>
  );
});
