import { IconPaperclip } from "@tabler/icons-react";

const AttachButton = ({ onClick }) => (
  <button
    type="button"
    className="btn-actions button-secondary"
    onClick={onClick}
  >
    <IconPaperclip size={20} stroke={1.5} />
  </button>
);

export default AttachButton;
