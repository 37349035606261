import { useEffect, useState } from "react";
import IconArrowDown from "../../../../assets/images/icons-new-layout/arrow-down.svg";
import { useGetIsLogged } from "../../../../hooks/smallHooks";
import {
  setConfigsMainChat,
  setOpenLogin,
} from "../../../../redux/general/action";
import ModalSelectModel from "../../../Modals/ModalSelectModel";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";

const ChangeMachine = ({ setSearchChat, state, dispatch, searchChat, background }) => {
  const [openModal, setOpenModal] = useState(false);
  const storedConfigs = JSON.parse(localStorage.getItem('configsMainChat'));
  const selectedMachine = storedConfigs?.machine?.name || storedConfigs?.machine;
  const isLogged = useGetIsLogged();

  // Agrupar e ordenar máquinas
  const groupAndSortMachines = machines => {
    const groupedMachines = machines.reduce((acc, machine) => {
      const owner = machine.architecture?.tokenizer || "Default";
      if (!acc[owner]) {
        acc[owner] = [];
      }
      acc[owner].push(machine);
      return acc;
    }, {});

    const sortedGroups = Object.keys(groupedMachines)
      .sort()
      .map(owner => ({
        owner,
        machines: groupedMachines[owner].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }));

    return sortedGroups;
  };

  // Filtrar e adicionar máquinas padrão se necessário
  const machinesToDisplay = groupAndSortMachines(
    state?.configsGeneralReducer?.listMachines?.filter(machine =>
      machine.name.toLowerCase().includes(searchChat.toLowerCase()) &&
      machine.architecture.modality !== "text+image->text" &&
      machine.architecture.modality === "text->text"
    ) || []
  );

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.SM;

  return (
    <>
      <div
        style={{
          border: "none",
          borderRadius: isMobile ? 6 : 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          background: background,
          padding: "0 12px",
          gap: 12,
          height: 40
        }}
        onClick={() => {
          if (isLogged) {
            setOpenModal(true);
          } else {
            dispatch(setOpenLogin(true));
          }
        }}
      >
        <span className="one-line text-capitalize">
          {selectedMachine}
        </span>
        <img src={IconArrowDown} height={10} width={10} alt="Arrow down" />
      </div>
      <ModalSelectModel
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSearchChat={setSearchChat}
        machinesToDisplay={machinesToDisplay}
      />
    </>
  );
};

export default ChangeMachine;
