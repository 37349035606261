import { useDispatch, useSelector } from "react-redux";
import { setIsLogged, setOpenLogin } from "../../redux/general/action";
import { CSSTransition } from "react-transition-group";
import SwitchWebMode from "./components/SwitchWebMode";
import Web2Login from "./components/Web2Login";
import Web3Login from "./components/Web3Login";
import iconCloseSideBar from "../../assets/images/icons-new-layout/close-side-bar.svg";
import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { getAndUpdateUser, getUser } from "../../services/userHelper";
import { requestChallenge, verifyChallenge } from "../../api";
import { useEffect, useState } from "react";
import { showErrorNotification } from "../../services/notifications";
import { ethers } from "ethers";

const STORAGE_KEYS = {
  USER_WALLET_SIGN: "signature",
  GOOGLE_LOGIN_REDIRECT: "google_login_redirect",
};

function GroupLogin({ isShow }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const supabaseClient = useSupabaseClient();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = getUser();
  const session = useSession();

  const googleRedirect = localStorage.getItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT);


  async function fetchAndSaveUserData() {
    try {
      setIsLoadingGoogle(true);
      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
      setIsLoadingGoogle(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSocialLogin() {
    try {
      setIsLoadingGoogle(true);

      localStorage.setItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT, "true");

      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${process.env.REACT_APP_PUBLIC_SITE_URL}`,
        },
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error(error);
      showErrorNotification(
        error?.message ??
          "Failed to login with Google, please try again or contact support"
      );
    } finally {
      setIsLoadingGoogle(false);
    }
  }

  async function handleLoginWithCredentials() {
    try {
      setIsLoading(true);
      const { error } = await supabaseClient.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        if (error.message === "Invalid login credentials") {
          alert("Invalid login credentials");
        }
        return;
      }

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLoginWithWeb3() {
    const signatureStorage = localStorage.getItem(
      STORAGE_KEYS.USER_WALLET_SIGN
    );

    if (signatureStorage) {
      return signatureStorage;
    }

    try {
      setIsLoading(true);
      const provider = new ethers.BrowserProvider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      const addressId = accounts[0];

      const challengeResponse = await requestChallenge(addressId);
      const challenge = challengeResponse;

      if (!challenge.success) {
        return showErrorNotification(
          challenge.data ?? "Failed to login with Metamask"
        );
      }

      const ethereum = await window?.ethereum;
      const signer = await new ethers.BrowserProvider(ethereum).getSigner();

      const signature = await signer.signMessage(challenge.data);

      const verifyResponse = await verifyChallenge({
        message: challenge.data,
        signature: signature,
      });

      const authData = verifyResponse;

      await supabaseClient.auth.signInWithPassword({
        email: authData.user.email,
        password: addressId.toLowerCase(),
      });

      await getAndUpdateUser();

      dispatch(setIsLogged(true));
      dispatch(setOpenLogin(false));
    } catch (error) {
      console.error(error?.message);
      showErrorNotification(
        error?.message ??
          "Failed to login with Metamask, please try again or contact support"
      );
    } finally {
      setIsLoading(false);
    }
  }

  
  useEffect(() => {
    if (!user && session && !googleRedirect && !isLoading) {
      fetchAndSaveUserData();
    }
  }, [session]);

  useEffect(() => {
    if (googleRedirect) {      
      dispatch(setOpenLogin(true));
      localStorage.removeItem(STORAGE_KEYS.GOOGLE_LOGIN_REDIRECT);
    }
  }, []);


  return (
    <CSSTransition
      in={isShow}
      classNames="slide-in-right-300"
      timeout={300}
      unmountOnExit
    >
      <section className="group-main-login">
        <button
          onClick={() => dispatch(setOpenLogin(false))}
          className="align-self-end mb-4 button-thirty"
          style={{
            height: 35,
            width: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            borderRadius: 8,
          }}
        >
          <img
            src={iconCloseSideBar}
            style={{
              transition: "all .2s",
              transform: `rotate(180deg)`,
            }}
            loading="lazy"
            height={18}
            width={18}
            alt=""
          />
        </button>
        <div className="header ">
          <div className="title mb-5">
            {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
              <h3>Sign In</h3>
            ) : (
              <h3>Connect Wallet</h3>
            )}
            <SwitchWebMode />
          </div>
          {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
            <p>
              New to AizzyApp? <button>Get Started</button>
            </p>
          ) : (
            ""
          )}
        </div>
        {state?.configsGeneralReducer?.webModeLogin === "Web 2" ? (
          <Web2Login
            handleLogin={handleLoginWithCredentials}
            handleSocialLogin={handleSocialLogin}
            isLoading={isLoading}
            isLoadingGoogle={isLoadingGoogle}
            onChangeEmail={e => setEmail(e.target.value)}
            onChangePassword={e => setPassword(e.target.value)}
          />
        ) : (
          <Web3Login handleLogin={handleLoginWithWeb3} isLoading={isLoading} />
        )}
      </section>
    </CSSTransition>
  );
}

export default GroupLogin;
