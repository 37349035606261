import { useEffect, useState } from "react";
import { IconHeart, IconX } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import COLORS from "../../utils/constants/colors";
import { QUERY_KEYS, queryClient } from "../../libs/react-query";
import { useQuery } from "react-query";
import { getMyAssistants } from "../../api/assistants";
import ButtonType1 from "../Buttons/ButtonType1";
import { useCreateNewChannel } from "../../hooks/smallHooks";
import { useNavigate } from "react-router-dom";
import { KEYS_MAIN_CHAT } from "../../services/chat/defaultConfigsMainChat";
import { setConfigsMainChat } from "../../redux/general/action";
import { useDispatch } from "react-redux";

const ModalAssistantInfo = ({ isOpen, toggleModal, assistant }) => {
  const { handleCreateChannel } = useCreateNewChannel();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useQuery(QUERY_KEYS.MY_ASSISTANTS, getMyAssistants, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const myAssistants = data?.assistants || [];

  // Estado local para o assistente selecionado
  const [selectedAssistant, setSelectedAssistant] = useState(assistant);

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    setSelectedAssistant(assistant);
  }, [assistant]);

  // Função para atualizar o assistente selecionado
  const handleSelectAssistant = (assistant) => {
    setSelectedAssistant(assistant);
  };

  return (
    <ModalGlobal
      open={isOpen}
      classNameChildren={`p-4 modal-children-info-assistants justify-content-start ${
        isOpen
          ? "animate__animated animate__fadeInDown animate__faster"
          : "animate__animated animate__fadeOut"
      }`}
      showBtnClose={false}
    >
      <div className="d-flex flex-column w-100 h-100 justify-content-between" style={{ gap: 32 }}>
        <header className="d-flex justify-content-end">
          <button
            className="d-flex align-items-center"
            style={{
              padding: "8px 8px",
              borderRadius: 6,
              background: COLORS.gray100,
            }}
            type="button"
            onClick={toggleModal}
          >
            <IconX stroke={1.5} style={{ width: 16, height: 16 }} />
          </button>
        </header>
        <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
          <div>
            <img
              src={selectedAssistant?.profileImage}
              alt=""
              style={{ width: 120, height: 120, borderRadius: "50%" }}
            />
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ gap: 12 }}
          >
            <div className="d-flex justify-content-center">
              <h3 className="two-line text-center">{selectedAssistant?.role}</h3>
            </div>
            <div className="d-flex justify-content-center gap-3">
              <label htmlFor="">Create by</label>
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={selectedAssistant?.profileImage}
                  alt=""
                  style={{ width: 20, height: 20, borderRadius: "50%" }}
                />
                {selectedAssistant?.name}
              </div>
            </div>
            <div className="px-4">
              <p className="text-center m-0 three-line">
                {selectedAssistant?.about ||
                  "Elevate your writing with AI-powered suggestions."}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3">
          <div
            className="d-flex flex-column gap-2 p-3"
            style={{
              width: "100%",
              background: COLORS.gray100,
              borderRadius: 16,
            }}
          >
            <h3># {myAssistants.length}</h3>
            <span>in Productivity</span>
          </div>
          <div
            className="d-flex flex-column gap-2 p-3"
            style={{
              width: "100%",
              background: COLORS.gray100,
              borderRadius: 16,
            }}
          >
            <div className="d-flex gap-1">
              <IconHeart />
              <h3>{selectedAssistant?.likes}</h3>
            </div>
            <span>Likes</span>
          </div>
        </div>
        <div className="gap-3 d-flex flex-column">
          <h4 className="m-0">More from this creator</h4>
          <div
            className="d-flex flex-column gap-3 overflow-auto"
            style={{ maxHeight: 165 }}
          >
            {myAssistants.map((assistant) => (
              <div
                key={assistant.id}
                className="d-flex align-items-center justify-content-start gap-3 px-3"
                style={{
                  background: COLORS.gray100,
                  borderRadius: 16,
                  minHeight: 100,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleSelectAssistant(assistant)}
              >
                <img
                  src={assistant.profileImage}
                  alt=""
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <div>
                  <h4 className="m-0">{assistant.name}</h4>
                  <span className="two-line">{assistant.about || "Enhance your writing skills with AI guidance for clear, impactful, and persuasive copy"}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <ButtonType1
            text={"Start Chat"}
            variation="primary"
            props={{
              className: "btn-socials",
              style: { width: "100%", height: 40, borderRadius: 10 },
              onClick: () => {
                toggleModal && handleCreateChannel() && handleClick();
                dispatch(
                  setConfigsMainChat(KEYS_MAIN_CHAT.assistant, selectedAssistant)
                );
              },
            }}
          />
        </div>
      </div>
    </ModalGlobal>
  );
};

export default ModalAssistantInfo;
