import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { APIClient } from "../api/apiCore";

const api = new APIClient();

// Decrease the calls based on the action.
function decreaseCalls(plan, action) {
  switch (action) {
    case "remainingPDFs":
      plan.remainingPDFs--;
      break;
    case "remainingChats":
      plan.remainingChats--;
      break;
    case "remainingImages":
      plan.remainingImages--;
      break;
    case "remainingVideos":
      plan.remainingVideos--;
      break;
    case "remainingSites":
      plan.remainingSites--;
      break;
    default:
      console.error("Invalid action: ", action);
  }
  return plan;
}

// Save the remaining calls to local storage.
const saveRemainingCallsToLocalStorage = async remainingCalls => {
  if (!remainingCalls) {
    console.error("No remaining calls to save");
    return Promise.resolve(); // Return a resolved promise
  }
  try {
    var oneHourInFuture = new Date().getTime() + 60 * 60 * 1000; // 60 minutes, 60 seconds, 1000 ms

    const encryptedData = await AES.encrypt(
      JSON.stringify(remainingCalls),
      process?.env?.REACT_APP_JWT_SECRET || ""
    ).toString();

    localStorage.setItem(
      "user-subscription",
      JSON.stringify({
        remainingCalls: encryptedData,
        date: new Date(),
        expiresIn: oneHourInFuture,
      })
    );

    return Promise.resolve(remainingCalls);
  } catch (error) {
    console.error("Failed to generate token: ", error);
    return Promise.reject(error);
  }
};

// Update the remaining calls and save them to local storage.
export function updateRemaining(action, remaining) {
  return saveRemainingCallsToLocalStorage(decreaseCalls(remaining, action));
}

// Fetch the remaining calls and save them to local storage.
export const fetchAndSaveRemainingCalls = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/user/subscription/remaining`
      );
      const remainingCalls = response;
      if (remainingCalls) {
        await saveRemainingCallsToLocalStorage(remainingCalls);
        resolve(remainingCalls);
      }
      resolve([]); // Resolve with a default value
    } catch (error) {
      console.error("Failed to fetch remaining calls: ", error);
      resolve([]); // Resolve with a default value
    }
  });
};

// Get the remaining calls from local storage if valid, otherwise fetch them.
export function getRemainingCalls() {
  const jwtToken = localStorage.getItem("user-subscription");

  if (jwtToken) {
    var storedItem = JSON.parse(jwtToken);

    if (storedItem) {
      var currentTime = new Date().getTime();

      // Subscription is active
      if (currentTime <= storedItem.expiresIn) {
        // Decrypt data
        const bytes = AES.decrypt(
          storedItem.remainingCalls,
          process?.env?.REACT_APP_JWT_SECRET || ""
        );
        const decryptedData = JSON.parse(bytes.toString(Utf8));

        return Promise.resolve(decryptedData);
      }
    }
  }

  // If token doesn't exist, or if it's invalid, or if it's expired
  return fetchAndSaveRemainingCalls();
}

export async function getRemainingCallsBySubscriptionPlan(action, planName) {
  const remainingCalls = await getRemainingCalls();
  return remainingCalls[action] || 0;
}
