import { useQuery } from "react-query";
import { getUserCredits } from "../../../api/creditPayments";
import { QUERY_KEYS } from "../../../libs/react-query";
import PurchaseCredits from "./Credits/PurchaseCredits";
import { PaymentHistory } from "./Credits/PaymentHistory";

const PlanSettings = () => {
  const { data: credits, isLoading: isLoadingCredits } = useQuery(
    QUERY_KEYS.USER_CREDITS,
    getUserCredits
  );

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <h4>Credits</h4>
        <PurchaseCredits
          credits={{
            amount: credits?.Amount || 0,
            isLoading: isLoadingCredits,
          }}
        />
      </div>
      <PaymentHistory />
    </>
  );
};

export default PlanSettings;
