// const ETHEREUM = {
//   name: 'Ethereum',
//   chainId: '1',
//   symbol: 'ETH',
// }

export const BASE = {
  name: "Base Mainnet",
  rpcUrl: "https://mainnet.base.org",
  chainId: "8453",
  symbol: "ETH",
};

export const BLOCKCHAINS = {
  BASE,
};

export const NETWORK_CONNECTIONS = [BASE];
