import { APIClient } from "./apiCore";

const api = new APIClient();

async function createWebsiteEmbeddings({ url, channelId }) {
  return api.create(
    `${process.env.REACT_APP_API_URL!}/api/embeddings/website`,
    {
      url,
      channelId,
    }
  );
}

const createDocumentEmbeddingUpload = (
  channelId,
  file: File,
  setUploadProgress?: (progress: number) => void
) => {
  return api.createWithFile(
    process.env.REACT_APP_API_URL + "/api/embeddings/upload/" + channelId,
    { document: file },
    setUploadProgress
  );
};

async function createYoutubeVideoEmbeddings({ url, channelId }) {
  return api.create(
    `${process.env.REACT_APP_API_URL!}/api/embeddings/youtube-video`,
    {
      url,
      channelId,
    }
  );
}

async function createTextEmbeddings({ text, channelId }) {
  return api.create(
    `${process.env.REACT_APP_API_URL!}/api/embeddings/text-embedding`,
    {
      text,
      channelId,
    }
  );
}

export {
  createWebsiteEmbeddings,
  createYoutubeVideoEmbeddings,
  createDocumentEmbeddingUpload,
  createTextEmbeddings,
};
