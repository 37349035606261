import { ethers } from "ethers";
import contractABI from "./TokenABI";
import { NETWORK_CONNECTIONS } from "./NetworkConnections";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS?.trim() || "";

const BASE = {
  name: "Base Mainnet",
  rpcUrl: "https://mainnet.base.org",
  chainId: "8453",
  symbol: "ETH",
};

interface Log {
  _type: string;
  address: string;
  blockHash: string;
  blockNumber: number;
  data: string;
  index: number;
  topics: string[];
  transactionHash: string;
  transactionIndex: number;
}

export interface TransactionReceipt {
  _type: "TransactionReceipt";
  blockHash: string;
  blockNumber: number;
  contractAddress: null | string;
  cumulativeGasUsed: string;
  from: string;
  gasPrice: string;
  blobGasUsed: null | string;
  blobGasPrice: null | string;
  gasUsed: string;
  hash: string;
  index: number;
  logs: Log[];
  logsBloom: string;
  status: number;
  to: string;
  wait: (confirmations?: number) => Promise<TransactionReceipt>;
}

const AIZ_TOKEN_ADDRESS = "0xc51d94a9936616b90e26abe61921ab3b4e66a149";

async function getMetaMaskProvider() {
  if (!window.ethereum) throw new Error(`No MetaMask found!`);
  await window.ethereum.send("eth_requestAccounts");

  const provider = new ethers.BrowserProvider(window.ethereum, "any");
  provider.on("network", (newNetwork, oldNetwork) => {
    if (oldNetwork) window.location.reload();
  });
  return provider;
}

async function getBalance(address: string) {
  const provider = await getMetaMaskProvider();
  const balance = await provider.getBalance(address);
  return ethers.formatEther(balance.toString());
}

async function transfer(toAddress: string, quantity: any) {
  const provider = await getMetaMaskProvider();
  const signer = provider.getSigner();
  ethers.getAddress(toAddress); // valida endereÃ§o

  // @ts-ignore
  const tx = await signer.sendTransaction({
    to: toAddress,
    value: ethers.parseEther(quantity),
  });

  return tx;
}

async function getTokenBalance(address: string, decimals = 18) {
  const provider = await getMetaMaskProvider();
  const contract = new ethers.Contract(contractAddress, contractABI, provider);
  const balance = await contract.balanceOf(address);

  return ethers.formatUnits(balance, decimals);
}

async function getTransaction(hash: string) {
  const provider = await getMetaMaskProvider();
  const tx = await provider.getTransactionReceipt(hash);
  return tx;
}

async function transferToken(toAddress: string, tokenAmount: number) {
  try {
    const provider = await getMetaMaskProvider();
    const signer = await provider.getSigner();

    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    // const amount = ethers.parseEther(tokenAmount.toString());
    const amount = ethers.parseEther("0.5");

    const txResponse: TransactionReceipt = await contract.transfer(
      process.env.REACT_APP_AIZ_TOKEN_ADDRESS || toAddress,
      amount
    );    

    return txResponse;
  } catch (error) {
    console.error("Error sending tokens:", error);
    throw error;
  }
}

async function getCurrentNetwork() {
  const provider = await getMetaMaskProvider();
  const network = await provider.getNetwork();
  // network.chainId = network.chainId.toString()

  return network;
}

async function changeNetwork(chainId: string) {
  const hexChainId = `0x${Number(chainId).toString(16)}`;

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: hexChainId }],
    });
  } catch (switchError: any) {
    if (switchError?.code === 4902) {
      const networkConnection = NETWORK_CONNECTIONS.find(
        connection => connection.chainId === chainId
      );

      if (!networkConnection) {
        return;
      }

      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: hexChainId,
              rpcUrls: [networkConnection.rpcUrl],
              chainName: networkConnection.name,
              nativeCurrency: {
                symbol: networkConnection.symbol,
              },
            },
          ],
        });
      } catch (addError: any) {
        throw new Error(`Failed to add the network: ${addError?.message}`);
      }
    } else {
      throw new Error(`Failed to switch the network: ${switchError?.message}`);
    }
  }
}

async function getCurrentAddress() {
  const provider = await getMetaMaskProvider();
  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  return address;
}

async function importTokenToMetaMask() {
  try {
    const provider = await getMetaMaskProvider();
    const currentNetwork = await getCurrentNetwork();

    if (currentNetwork.chainId.toString() !== BASE.chainId) {
      await changeNetwork(BASE.chainId);
    }

    const tokenContract = new ethers.Contract(
      AIZ_TOKEN_ADDRESS,
      contractABI,
      provider
    );

    const symbol = await tokenContract.symbol();

    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        // @ts-ignore
        type: "ERC20",
        options: {
          address: AIZ_TOKEN_ADDRESS,
          symbol: symbol,
          decimals: 18,
          image: "https://basescan.org/token/images/aizzy_32.png?=v5",
        },
      },
    });
  } catch (error) {
    console.error("Erro ao importar o token no MetaMask:", error);
    throw error;
  }
}

export const web3Service = {
  getMetaMaskProvider,
  getBalance,
  getTokenBalance,
  transfer,
  transferToken,
  getTransaction,
  getCurrentNetwork,
  changeNetwork,
  getCurrentAddress,
  importTokenToMetaMask,
};
