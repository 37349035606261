import { APIClient } from "./apiCore";

const api = new APIClient();
const paymentApi = "https://credit-go-137cc7c9e26a.herokuapp.com/api/v1";

// Interfaces de retorno de dados

export interface PaymentUserResponse {
  ID: string;
  UserID: string;
  Amount: number;
  Method: string; // ex: "credit_card", "pix"
  TransactionID: string;
  Status: string; // ex: "pending", "completed"
  PaymentURL: string; // URL for payment completion if applicable
  Error: string; // Error message if any
  CreatedAt: string; // ISO 8601 date-time string
  UpdatedAt: string; // ISO 8601 date-time string
}

export interface CreditsResponse {
  ID: string;
  Amount: number;
  UserID: string;
  CreatedAt: string; // ISO 8601 date-time string
  UpdatedAt: string; // ISO 8601 date-time string
}

export interface PaymentResponse {
  id: string;
  amount: number;
  status: string; // ex: "pending", "completed"
  payment_url: string; // URL for payment completion
  pix?: {
    owner: string; // Owner of the PIX account
    personalid: string; // CNPJ or CPF
    keypix: string; // PIX key
    qrcode: string; // Base64 QR code
    txinfoemvqrcps: string; // Transaction information for copy-paste
  };
}

interface PaymentRequest {
  amount: number;
  method: "card" | "pix" | "token";
  transaction_id?: string;
}

const creditApiKey = process.env.REACT_APP_CREDIT_API_KEY;

const getHeaders = () => ({
  "x-api-key": creditApiKey,
});

export async function getUserCredits(): Promise<CreditsResponse> {
  return api.get(`${paymentApi}/credit/user`, {
    headers: getHeaders(),
  });
}

// GET /payment/user
export async function getUserPayment(): Promise<PaymentUserResponse[]> {
  return api.get(`${paymentApi}/payment/user`, {
    headers: getHeaders(),
  });
}

// POST /payment
export async function postPayment(data: PaymentRequest): Promise<PaymentResponse> {
  return api.create(
    `${paymentApi}/payment`,
    data,
    {
      headers: getHeaders(),
    }
  );
}
