const COLORS = {
  white: "#FFFFFF",
  gray100: "#F2F4F7",
  gray200: "#E4E7EC",
  gray300: "#D0D5DD",
  gray400: "#98A2B3",
  gray500: "#667085",
  gray700: "#344054",
  gray800: "rgb(29, 41, 57)",
  gray900: "#101828",
};

export default COLORS;
