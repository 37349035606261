/* eslint-disable no-unused-expressions */
import { useDispatch, useSelector } from "react-redux";
import { setConfigsMainChat } from "../../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import { IconLoader2, IconMessages } from "@tabler/icons-react";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import { useGetConfigsMainChat } from "../../../../hooks/smallHooks";
import { useLocation } from "react-router-dom";
import DropdownTools from "../Chats/ToolsTopBar/DropdownTools";
import { useEffect, useRef, useState } from "react";
import ButtonAsideType1 from "../../../Buttons/ButtonAsideType1";
import COLORS from "../../../../utils/constants/colors";

const GroupListChats = ({ title = "Title", chats = [] }) => {
  const [openTools, setOpenDropdownTools] = useState({});
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [loadingChatId, setLoadingChatId] = useState(null);
  const location = useLocation();

  const configsMainChat = useGetConfigsMainChat();

  const sizeIcon =
    state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.LG ? 21 : 18;

  function handleSelectChat(chat) {
    dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, chat));
    setOpenDropdownTools({});
  }

  const toggleDropdown = (chatId, e) => {
    e.stopPropagation();
    setOpenDropdownTools(prevState => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  const handleClickOutside = event => {
    dropdownRef.current && !dropdownRef.current.contains(event.target);
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownTools({});
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="group-list-chats" ref={dropdownRef}>
      {title && <h6>{title}</h6>}
      <ul>
        {chats.map((chat, index) => (
          <li key={index}>
            <ButtonAsideType1
              onClick={() => {
                handleSelectChat(chat);
                if (location.pathname !== "/") {
                  window.location.href = "/";
                }
              }}
              hover
              props={{
                style: {
                  justifyContent: loadingChatId === chat._id ? "center" : "flex-start",
                  width: "100%",
                  backgroundColor:
                    configsMainChat?.chatData?._id === chat._id
                      ? COLORS.white
                      : "",
                  color:
                    configsMainChat?.chatData?._id === chat._id
                      ? COLORS.gray800
                      : "",
                },
              }}
            >
              {!state.configsGeneralReducer.openLeftBar && (
                <IconMessages size={sizeIcon} style={{ minWidth: sizeIcon }} />
              )}
              {state?.configsGeneralReducer?.openLeftBar &&
                (loadingChatId === chat._id ? (
                  <IconLoader2 />
                ) : (
                  <span className="one-line position-relative align-items-center w-100 d-flex justify-content-between">
                    {chat?.channelName}
                    {configsMainChat?.chatData?._id === chat._id && (
                      <span
                        style={{
                          position: "absolute",
                          top: -4,
                          right: 0,
                          cursor: "pointer",
                          color: COLORS.gray800,
                        }}
                        onClick={e => toggleDropdown(chat._id, e)}
                      >
                        ...
                      </span>
                    )}
                  </span>
                ))}
            </ButtonAsideType1>
            <div
              className={`dropdown-tools-group-chats ${openTools[chat._id] ? "show" : ""
                }`}
            >
              <DropdownTools
                channel={configsMainChat}
                setLoading={isLoading => {
                  if (isLoading) {
                    setLoadingChatId(chat._id); 
                  } else {
                    setLoadingChatId(null);
                  }
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default GroupListChats;
