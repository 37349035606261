import { useState, useRef } from "react";
import axios from "axios";
import {
  IconMicrophone,
  IconLoader2,
  IconPlayerPauseFilled,
  IconSend2,
  IconTrash,
} from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { useGetIsLogged } from "../../../../../hooks/smallHooks";
import { useChatStore } from "../../../../../state/chat";
import { getCurrentUserLanguage } from "../../../../../services/userHelper";
import { setOpenLogin } from "../../../../../redux/general/action";
import { showErrorNotification } from "../../../../../services/notifications";

const AudioRecorderButton = ({ onStopRecorder, onAudioProcessing }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const dispatch = useDispatch();

  const isLogged = useGetIsLogged();

  const { isReceivingMessage } = useChatStore();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const userLanguage = getCurrentUserLanguage();

  async function startRecording() {
    if (!isLogged) {
      dispatch(setOpenLogin(true));
      return;
    }
    try {
      audioChunksRef.current = [];
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;

      streamRef.current = stream;

      setIsRecording(true);
      onAudioProcessing(true);
    } catch (error) {
      console.error("Error starting recording: ", error);
      showErrorNotification(error?.message || "Error starting recording");
    }
  }

  async function pauseRecording() {
    if (isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
      setAudioURL(URL.createObjectURL(audioBlob));
    }
  }

  async function resumeRecording() {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  }

  async function stopRecording() {
    if (!isRecording) return;

    if (mediaRecorderRef.current) {
      setIsLoading(true);
      return new Promise(resolve => {
        mediaRecorderRef.current.onstop = async () => {
          try {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/mp3",
            });

            const formData = new FormData();
            formData.append("audio", audioBlob, "audio.mp3");
            formData.append("userLanguage", userLanguage);

            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/chat/upload-audio`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            onStopRecorder(response.transcription);
          } catch (error) {
            console.error("Error uploading audio:", error);
            showErrorNotification(error?.message || "Error uploading audio");
          } finally {
            setIsLoading(false);
            audioChunksRef.current = [];
            setAudioURL(null);
            resolve();
            onAudioProcessing(false);
          }
        };

        mediaRecorderRef.current.stop();
        setIsRecording(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
      });
    } else {
      console.error("MediaRecorder not initialized.");
      showErrorNotification("MediaRecorder not initialized.");
    }
  }

  async function cancelRecording() {
    if (isRecording || isPaused) {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        audioChunksRef.current = [];
        setAudioURL(null);
        setIsRecording(false);
        setIsPaused(false);

        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        onAudioProcessing(false);
      }
    }
  }

  return (
    <div>
      {isRecording || isLoading ? (
        <div className="d-flex align-items-center gap-2">
          {/* TODO: Add audio player */}
          {/* {isPaused && audioURL ? <audio controls src={audioURL} /> : null} */}
          <button
            type="button"
            className="btn-actions button-secondary"
            onClick={isPaused ? resumeRecording : pauseRecording}
            disabled={isLoading}
          >
            {isPaused ? (
              <IconMicrophone size={20} stroke={1.5} />
            ) : (
              <IconPlayerPauseFilled size={20} stroke={1.5} />
            )}
          </button>
          <button
            type="button"
            className="btn-actions button-secondary"
            onClick={cancelRecording}
            disabled={isLoading}
          >
            <IconTrash size={20} stroke={1.5} />
          </button>
          <button
            type="button"
            className="btn-actions button-secondary"
            onClick={stopRecording}
            disabled={!isRecording || isLoading}
          >
            {isRecording ? (
              <IconSend2 size={20} stroke={1.5} />
            ) : (
              <IconLoader2 className="spin" size={20} stroke={1.5} />
            )}
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="btn-actions button-secondary"
          onClick={startRecording}
          disabled={isRecording || isReceivingMessage}
        >
          <IconMicrophone size={20} stroke={1.5} />
        </button>
      )}
    </div>
  );
};

export default AudioRecorderButton;
