import iconMetaMask from "../../../assets/images/icons-new-layout/metamask.svg";
import ButtonType1 from "../../Buttons/ButtonType1";
import ModalInstallMetamask from "../../Modals/ModalInstallMetamask";
import { useState } from "react";

const Web3Login = ({ handleLogin = () => {}, isLoading }) => {
  const [showModalInstallMetamask, setShowModalInstallMetamask] =
    useState(false);

  const handleClickMetamask = () => {
    if (window.ethereum) {
      handleLogin();
    } else {
      setShowModalInstallMetamask(true);
    }
  };
  return (
    <div>
      <ButtonType1
        icon={iconMetaMask}
        text={"Sign in with Metamask"}
        variation="thirty"
        isLoading={isLoading}
        props={{
          className: "btn-socials w-100 mt-3",
          type: "button",
          style: { borderRadius: 8, padding: "13px 20px" },
          onClick: () => {
            handleClickMetamask();
          },
        }}
      />
      <ModalInstallMetamask
        onclick={() => setShowModalInstallMetamask(false)}
        modalInstallMetamask={showModalInstallMetamask}
        iconMetaMask={iconMetaMask}
      />
    </div>
  );
};

export default Web3Login;
