import {
  IconChevronDown,
  IconPower,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/smallHooks";
import DetailsUserBalance from "./DetailsUserBalance";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";


const MenuWalletNewLayout = ({ toggle, dropdownOpen, user }) => {
  const isMobile = window.matchMedia("(max-width: 567px)").matches;
  const logout = useLogout();
  const navigate = useNavigate();
  return (
    <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav className="box-account">
        <img src={imgProfile} alt="" />
        <span className={"one-line"}>{user?.email?.split("@")[0]}</span>
        <IconChevronDown
          stroke={1}
          size={14}
          style={{
            transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease-in-out",
          }}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-account mt-1 p-4 p-sm-3">
        <div className="d-flex justify-content-between">
          <h4>Wallet</h4>
          <div>
            <IconSettings />
            <IconPower
              onClick={() => {
                logout();
                toggle(!dropdownOpen);
              }}
              style={{ color: "red", marginLeft: 8, cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-3 mt-sm-3 mt-4">
          <DetailsUserBalance />
          <DropdownItem className="d-flex align-items-center justify-content-between">
            <div>
              <i className={"bx bx-cog"} />
              Earned +1080.92
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            onClick={() => {
              navigate("/assistants");
            }}
          >
            <div>
              <i className={"bx bx-bot"} />
              Assitants
            </div>
            <i className={"bx bx-chevron-right"} />
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuWalletNewLayout;
