export const setConfigsMainChat = (key, value) => {
  return {
    type: "SET_CONFIGS_MAIN_CHAT",
    payload: {
      ...JSON.parse(localStorage.getItem("configsMainChat")),
      ...(key && { [key]: value }),
    },
  };
};

export const setListAssistants = state => {
  return {
    type: "SET_LIST_ASSISTANTS",
    payload: state,
  };
};

export const setListMachines = state => {
  return {
    type: "SET_LIST_MACHINES",
    payload: state,
  };
};

export const setWindowWidth = state => {
  return {
    type: "SET_WINDOW_WIDTH",
    payload: state,
  };
};

export const setOpenLogin = state => {
  return {
    type: "SET_OPEN_LOGIN",
    payload: state,
  };
};

export const setIsLogged = state => {
  return {
    type: "SET_IS_LOGGED",
    payload: state,
  };
};

export const setWebModeLogin = state => {
  return {
    type: "SET_WEB_MODE_LOGIN",
    payload: state,
  };
};

export const setOpenLeftBar = state => {
  return {
    type: "SET_OPEN_LEFT_BAR",
    payload: state,
  };
};

export const setOpenLeftBarMobile = state => {
  return {
    type: "SET_OPEN_LEFT_BAR_MOBILE",
    payload: state,
  };
};

export const setListTypeChats = state => {
  return {
    type: "SET_LIST_TYPE_CHATS",
    payload: state,
  };
};

export const setLanguageAction = (language) => {
  return {
    type: 'SET_LANGUAGE',
    payload: language,
  };
};

