export const ModalInstallMetamaskI18n  = {
    "pt-BR": {
      description: {
        label: "Parece que você não tem a carteira MetaMask instalada no seu dispositivo. Clique no botão abaixo para instalar a MetaMask!",
      },
      button: {
        download: "Baixar",
        close: "Voltar",
      },
    },
    "en-US": {
      description: {
        label: "It seems you don't have the MetaMask wallet installed on your device. Click the button below to install MetaMask!",
      },
      button: {
        download: "Download",
        close: "Close",
      },
    },
    "es-ES": {
      description: {
        label: "Parece que no tienes la cartera MetaMask instalada en tu dispositivo. ¡Haz clic en el botón de abajo para instalar MetaMask!",
      },
      button: {
        download: "Descargar",
        close: "Cerrar",
      },
    },
    "fil-PH": {
      description: {
        label: "Mukhang wala kang MetaMask wallet na naka-install sa iyong device. I-click ang button sa ibaba para i-install ang MetaMask!",
      },
      button: {
        download: "I-download",
        close: "Isara",
      },
    },
  };
  