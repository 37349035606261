import { useCallback, useEffect, useState } from "react";
import { showErrorNotification } from "../services/notifications";
import { useChatStore } from "../state/chat";

import { getUser } from "../services/userHelper";
import { useGetConfigsMainChat } from "./smallHooks";
import { getBearerToken } from "../services/localStorageHelpers";
import {
  ChatRepository,
  makeChatId,
} from "../services/indexedDB/chat-repository";
import { QUERY_KEYS, queryClient } from "../libs/react-query";
import { ChannelDTO } from "../@DTO/channel";
import { renameChannel } from "../api";

export interface MessageEndData {
  event: string;
  data: {
    user: string;
    date: string;
    datetime: string;
    chat_request: string;
    chat_response: {
      role: string;
      content: string;
    };
    parentMongoId: any;
    assistantId: string;
    channelId: string;
    flagImage: boolean;
    userEmail: string;
    _id: string;
  };
  messageId: string;
}

const useChatWebSocket = () => {
  const [message] = useState<any>("");

  const [isConnected, setIsConnected] = useState(false);
  const [webSocket, setWebSocket] = useState<any>(null);
  const [localInterval, setLocalInterval] = useState<any>(null);
  const configsMainChat = useGetConfigsMainChat();

  const selectedChat = configsMainChat?.chatData;

  const {
    setStatus,
    setImageMessage,
    setEndMessage,
    setBroadcastMessage,
    updateMessage,
    errorReceiveMessageManager,
    setDisableCancelMessage,
    reset,
  } = useChatStore();

  const chatRepository = new ChatRepository();

  const user = getUser();
  const token = getBearerToken();

  const [retryCount, setRetryCount] = useState(1);

  const createWebSocket = useCallback(() => {
    const socketDomain = "wss://chat-go-8f73c179d397.herokuapp.com/chat";

    const ws = new WebSocket(
      `${socketDomain}?channelId=${selectedChat._id}&authorization=${token}`
    );

    return ws;
  }, [selectedChat]);

  function sendWebsocketMessage(message) {
    if (!selectedChat?._id || !token || !retryCount) return;

    if (webSocket && isConnected) {
      return webSocket.send(message);
    }

    const ws = createWebSocket();

    ws.onopen = () => {
      setWebSocket(ws);
      setIsConnected(true);
      ws.send(message);
    };

    ws.onmessage = ({ data }) => {
      if (data === "ping") {
        ws.send("pong");
        return;
      }

      if (!isValidMessage(data)) {
        return;
      }

      const message = JSON.parse(data);

      if (message?.content?.flagImage === true) {
        return setImageMessage(message?.content);
      }

      updateSocketMessage(message);
    };
  }

  async function updateSocketMessage(message) {
    const hasResponse = message?.data?.chat_response?.content !== "";

    switch (message.event) {
      case "status":
        setStatus(message?.content);
        break;

      case "end":
        setEndMessage(message);

        if (hasResponse) {
          await chatRepository.updateChatWithMessage(
            makeChatId(message.data.channelId, user?.id!),
            message?.data
          );
        }

        setDisableCancelMessage(false);

        setStatus("");
        reset();
        break;

      case "saving-chat":
        setDisableCancelMessage(true);
        break;

      case "stopped":
        break;

      case "suggestion-channel-name":
        const suggestionChannelName = message?.suggestion;

        if (suggestionChannelName) {
          queryClient.setQueryData<ChannelDTO[]>(
            QUERY_KEYS.CHANNELS,
            cachedChannels => {
              const newChannels = cachedChannels?.map(channel => {
                if (channel._id === selectedChat._id) {
                  return { ...channel, channelName: suggestionChannelName };
                }
                return channel;
              });

              return newChannels || [];
            }
          );

          const params = {
            channelId: selectedChat._id,
            channelName: suggestionChannelName,
          };
          renameChannel(params);
        }

        break;

      case "broadcast":
        if (hasResponse) {
          setBroadcastMessage(message);
        }

        break;

      case "error":
        errorReceiveMessageManager();
        reset();
        break;

      default:
        if (message.error) {
          errorReceiveMessageManager();
          reset();
          return showErrorNotification(message.error);
        }

        if (message?.content) {
          updateMessage(message);
        }
    }
  }

  function cancelMessage() {
    if (webSocket && isConnected) {
      setDisableCancelMessage(true);
      webSocket.send(JSON.stringify({ event: "stop-message" }));
    }
  }

  function isValidMessage(data) {
    const jsonRegex = /^[\],:{}\s]*$/;
    const sanitizedData = data
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      );
    return jsonRegex.test(sanitizedData);
  }

  useEffect(() => {
    if (!selectedChat?._id || !token || !retryCount) return;

    if (isConnected && webSocket) return;

    const ws = createWebSocket();

    ws.onopen = () => {
      setWebSocket(ws);
      setIsConnected(true);
      reset();
    };

    ws.onmessage = ({ data }) => {
      if (data === "ping") {
        ws.send("pong");
        return;
      }

      if (!isValidMessage(data)) {
        return;
      }

      const message = JSON.parse(data);

      if (message?.content?.flagImage === true) {
        return setImageMessage(message?.content);
      }

      updateSocketMessage(message);
    };

    ws.onerror = error => {
      console.error(`WebSocket error:, ${error}`);
      reset();
    };

    ws.onclose = () => {
      // if (isReceivingMessage) {

      // }

      setWebSocket(null);
      setIsConnected(false);
      setRetryCount(retryCount + 1);
    };

    return () => {
      if (webSocket) {
        webSocket.close();
      }
      if (ws) {
        ws.close();
      }
    };
  }, [selectedChat, token]);

  useEffect(() => {
    function sendPing() {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.send("ping");
      } else {
        setWebSocket(null);
      }
    }
    if (localInterval) {
      clearInterval(localInterval);
    }

    if (webSocket) {
      const newInterval = setInterval(sendPing, 30000); // 30 seconds
      setLocalInterval(newInterval);
    }

    return () => {
      if (localInterval) {
        clearInterval(localInterval);
      }
    };
  }, [webSocket]);

  return {
    sendWebsocketMessage,
    cancelMessage,
    message,
  };
};

export default useChatWebSocket;
