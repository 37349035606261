import { IconLoader2, IconX } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import ButtonType1 from "../Buttons/ButtonType1";

const ModalImagesAvatar = ({
  showModal,
  loadingImage,
  generatedImages,
  onSelectGeneratedImage,
  closeModal,
  handleAvatarChange,
  handleGenerateImage
}) => {
  return (
    <ModalGlobal
      open={showModal}
      showBtnClose={false}
      onClick={closeModal}
      classNameChildren={"modal-select-avatar p-4 gap-5"}
    >
      <div className="d-flex justify-content-between w-100">
        <h4>Escolha uma imagem para o seu assistente</h4>{" "}
        <button onClick={closeModal}>
          <IconX />
        </button>
      </div>
      {loadingImage && (
        <div className="col-12 my-4 d-flex align-items-center justify-content-center">
          <IconLoader2 />
        </div>
      )}
      <div className="d-flex">
        {!loadingImage &&
          generatedImages.map((image, index) => (
            <>
              {image.url ? (
                <button
                  onClick={() => {
                    onSelectGeneratedImage(image.url);
                    handleAvatarChange(image.url)
                    closeModal();
                  }}
                  className="button-select-assistant-image"
                  key={index}
                >
                  <img src={image.url} alt="" />
                </button>
              ) : (
                <div className="d-flex flex-column g-3 align-items-center justify-content-center">
                  <IconLoader2 />
                  <small>error</small>
                </div>
              )}
            </>
          ))}
      </div>
      <div className="d-flex justify-content-center gap-3">
      <ButtonType1
        text={"Close"}
        variation="primary"
        props={{
          style: {
            padding: "10px 16px",
            borderRadius: 10,
            marginRight: 12,
          },
          onClick: closeModal,
        }}
      />
      <ButtonType1
        text={"Regenerate"}
        variation="primary"
        props={{
          style: {
            padding: "10px 16px",
            borderRadius: 10,
            marginRight: 12,
          },
          onClick: handleGenerateImage,
        }}
      />
      </div>
    </ModalGlobal>
  );
};

export default ModalImagesAvatar;
