import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import { QUERY_KEYS, queryClient } from "../../../libs/react-query";
import { useState } from "react";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";

const ListAssistantsAizzy = () => {
  const dispatch = useDispatch();
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const data = queryClient.getQueryData(QUERY_KEYS.AIZZY_ASSISTANTS);

  const handleCardClick = (assistant) => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  return (
    <div className="grid-vertical-assistants">
      {data?.assistants?.map((assistant, index) => (
        <CardAssistantsNewLayout
          setOpenModalInfo={() => handleCardClick(assistant)}
          key={index}
          direction="vertical"
          image={assistant.profileImage}
          title={assistant.name}
          description={assistant.role}
          icon={assistant.profileImage}
          name={assistant.role}
          likes={assistant?.likes}
          onSelect={() =>
            dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant))
          }
        />
      ))}
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => { setOpenModalInfo(false) }}
        assistant={selectedAssistant}
      />
    </div>
  );
};

export default ListAssistantsAizzy;
