/* eslint-disable no-undef */
import { IconSearch, IconX } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import COLORS from "../../utils/constants/colors";
import { KEYS_MAIN_CHAT } from "../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import { setConfigsMainChat } from "../../redux/general/action";
import { useEffect, useState } from "react";

const ModalSelectModel = ({
  openModal,
  setOpenModal,
  setSearchChat,
  machinesToDisplay,
  setSelectedMachine,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("chat");

  const tabs = [
    {
      id: "chat",
      label: "Chat",
    },
    {
      id: "image",
      label: "Image",
    },
  ];

  return (
    <ModalGlobal
      open={openModal}
      classNameChildren={`p-4 modal-children-select-model justify-content-start ${openModal
          ? "animate__animated animate__fadeInDown animate__faster"
          : "animate__animated animate__fadeOut"
        }`}
      showBtnClose={false}
    >
      <div className="d-flex flex-column  gap-4 w-100">
        <header className="d-flex justify-content-between">
          <h3 className="m-0">Select model</h3>
          <button
            className="d-flex align-items-center"
            style={{
              padding: "4px 8px",
              borderRadius: 8,
              background: COLORS.gray100,
            }}
            type="button"
            onClick={() => {
              setOpenModal(false);
              setSearchChat("");
              setActiveTab("chat");
            }}
          >
            <IconX stroke={1.5} style={{ width: 16, height: 16 }} />
          </button>
        </header>
        <div className="d-flex flex-column gap-2">
          <div
            className="d-flex gap-2 px-3 rounded-4 align-items-center"
            style={{ background: "#F2F4F7" }}
          >
            <IconSearch stroke={1.5} size={14} />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search for an model"
              style={{ height: 44, color: COLORS.gray500 }}
              onChange={e => {
                setSearchChat(e.target.value);
              }}
            />
          </div>
        <div className="d-flex gap-2">
          {tabs.map(tab => (
            <button
              type="button"
              key={tab.id}
              className={`tab-button one-line ${activeTab === tab.id ? "active" : ""
                }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <i className={tab.icon} />
              {tab.label}
            </button>
          ))}
        </div>{" "}
        </div>
        {activeTab === "chat" ? (
          <div
            className="d-flex flex-column gap-4 overflow-auto"
            style={{ maxHeight: 600 }}
          >
            {machinesToDisplay.map((group, index) => (
              <div key={index}>
                <div className="d-flex justify-content-center">
                  <h4>{group.owner}</h4>
                </div>
                <ul>
                  {group.machines.map(machine => (
                    <li
                      key={machine.id}
                      onClick={() => {
                        dispatch(
                          setConfigsMainChat(KEYS_MAIN_CHAT.machine, machine.id)
                        );
                        setOpenModal(false);
                        setSearchChat("");
                      }}
                    >
                      {machine.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <h4>Shortly...</h4>
          </div>
        )}
      </div>
    </ModalGlobal>
  );
};

export default ModalSelectModel;
